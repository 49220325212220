import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import swal from "sweetalert";
import { useTheme } from "@mui/material/styles";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { Person } from "@mui/icons-material";
import { useState } from "react";
import {
  getEmployees,
  updateContract,
} from "../../../services/labourContractUpdate/labourContractUpdateService";
import { useEffect } from "react";
import { Form, Formik } from "formik";
import { getPagesData } from "../../../services/documentations/ContractServices";
import LabourPeriod from "./component/LabourPeriod";
import Wage from "./component/Wage";
import PhysicalEducationSport from "./component/PhysicalEducationSport";
import SocialProtection from "./component/SocialProtection";
import RegulationPropertyRelation from "./component/RegulationPropertyRelation";
import WorkVacationTime from "./component/WorkVacationTime";
import Protection from "./component/Protection";
import VacationForDialog from "./component/VacationForDialog";
import { removeEmptyStrings } from "../../../utils/removeEmptyString";
import {
  empConstInitialValues,
  empConstModifeValue,
  weekDays,
} from "./empConstMock/empConstMock";
const InputCont = styled.div`
  width: 100%;
  padding: 0px 20px;
  margin-bottom: 10px;
`;

export default function EmpContsDialog({ fetchData, open, setOpen }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [emps, setEmps] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState("");
  const [selectedEmpData, setSelectedEmpData] = useState(null);
  const [selectedCont, setSelectedCont] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);
  const [inventoryTypes, setInventoryTypes] = useState([]);

  const handleFetchEmps = async () => {
    try {
      const response = await getEmployees();
      const res = await getPagesData("reasonOfTemporaryContract");

      setSelectedValue(res?.contracts?.static?.labour);
      setEmps(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    handleFetchEmps();
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const modifeValue = empConstModifeValue(values, selectedCont);

    const result = removeEmptyStrings(modifeValue);

    try {
      const willSubmit = await swal({
        title: "Əminsiniz mi?",
        text: "Mövcud müqaviləyə dəyişiklik etməyə əminsiniz?",
        icon: "warning",
        dangerMode: true,
        buttons: ["Ləğv et", "Tətbiq et"],
      });
      if (willSubmit) {
        const resp = await updateContract(result);
        if (resp) {
          handleClose();
          swal(
            "Tamamlandı!",
            "Əmək müqavilələrinə dəyişiklik sorğusu göndərildi!",
            "success"
          );
          resetForm();
          await fetchData();
        }
      }
    } catch (error) {
      swal("Xəta", "Xəta baş verdi", "error");
      console.log("err", error);
    }

    setSubmitting(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            width: "70vw",
            maxWidth: "none",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }} id="responsive-dialog-title">
          {"Ə.M. dəyişiklik"}
        </DialogTitle>
        <DialogContent sx={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <Formik
              initialValues={empConstInitialValues}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                setFieldValue,
                isValid,
                dirty,
              }) => (
                <>
                  <Form style={{ width: "100%" }}>
                    <div style={{ width: "100%", display: "flex" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "400px",
                          overflow: "scroll",
                        }}
                      >
                        {selectedCont && (
                          <>
                            <LabourPeriod
                              values={values}
                              setFieldValue={setFieldValue}
                              selectedValue={selectedValue}
                            />
                            <Wage
                              values={values}
                              weekDays={weekDays}
                              setFieldValue={setFieldValue}
                              selectedValue={selectedValue}
                            />
                            <Protection
                              values={values}
                              setFieldValue={setFieldValue}
                              selectedValue={selectedValue}
                              inventoryTypes={inventoryTypes}
                            />
                            <WorkVacationTime
                              values={values}
                              weekDays={weekDays}
                              selectedValue={selectedValue}
                              setFieldValue={setFieldValue}
                            />
                            <VacationForDialog
                              selectedValue={selectedValue}
                              setFieldValue={setFieldValue}
                              selectedEmpData={selectedEmpData}
                            />
                            <PhysicalEducationSport
                              setFieldValue={setFieldValue}
                            />
                            <SocialProtection setFieldValue={setFieldValue} />
                            <RegulationPropertyRelation
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </>
                        )}
                      </div>
                      <div style={{ width: "100%" }}>
                        <Typography
                          sx={{ paddingLeft: "10px", marginBottom: "10px" }}
                          variant="h4"
                        >
                          Əmək müqaviləsinə dəyişiklik ediləcək əməkdaşı seçin:
                        </Typography>
                        <InputCont>
                          <FormControl fullWidth>
                            <InputLabel id="employee-label">Əməkdaş</InputLabel>
                            <Select
                              label={"Əməkdaş"}
                              labelId="employee-label"
                              placeholder="Əməkdaş seçin..."
                              value={selectedEmp && selectedEmp}
                              onChange={(e) => {
                                const val = e.target.value;
                                setSelectedEmp(val);
                                const selectedEmployee = emps.find(
                                  (emp) => emp.employee._id === val
                                );
                                if (selectedEmployee) {
                                  setInventoryTypes(
                                    selectedEmployee?.employee?.inventories ||
                                      []
                                  );
                                  setSelectedEmpData(selectedEmployee);
                                }
                              }}
                            >
                              <MenuItem hidden value={""}>
                                Əməkdaş seçin...
                              </MenuItem>
                              {emps &&
                                emps?.map((emp) => {
                                  const employee = emp?.employee;
                                  const organizationName =
                                    employee?.laborActivity?.length > 0
                                      ? employee?.laborActivity?.[0]
                                          ?.organizationName
                                      : "";
                                  return (
                                    <MenuItem
                                      key={employee?._id}
                                      value={employee?._id}
                                      selected={employee?._id}
                                    >{`${employee?.lastname} ${
                                      employee?.firstname
                                    } ${employee?.fathername}${
                                      organizationName
                                        ? ` - ${organizationName}`
                                        : ""
                                    }`}</MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>

                          <FormControl fullWidth sx={{ marginTop: "10px" }}>
                            <InputLabel id="cont-label">
                              Əmək müqavilələri
                            </InputLabel>
                            <Select
                              disabled={!selectedEmp}
                              label={"Əmək müqavilələri"}
                              labelId="cont-label"
                              value={selectedCont}
                              onChange={(e) => {
                                const val = e.target.value;
                                setSelectedCont(val);
                              }}
                            >
                              {emps &&
                                emps
                                  ?.filter(
                                    (emp, i) =>
                                      emp?.employee?._id === selectedEmp
                                  )
                                  .map((item, index) => {
                                    return (
                                      <MenuItem
                                        value={item?.staffs?.[index]?._id}
                                      >
                                        {
                                          item?.staffs?.[index]?.contract?.data
                                            ?.docNumber
                                        }
                                      </MenuItem>
                                    );
                                  })}
                            </Select>
                          </FormControl>
                        </InputCont>
                        <div
                          style={{
                            width: "96%",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginRight: "10px",
                          }}
                        >
                          <IconButton
                            onClick={() =>
                              window.open(`/employees/${selectedEmp}/view`)
                            }
                            sx={{ borderRadius: "5px" }}
                            disabled={!selectedEmp}
                          >
                            <Person />
                            <Typography>Əməkdaş haqqında</Typography>
                          </IconButton>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        gap: "10px",
                        paddingBottom: "15px",
                      }}
                    >
                      <Button
                        color="error"
                        variant="contained"
                        title="Ləğv et"
                        sx={{ width: "50%" }}
                        autoFocus
                        onClick={handleClose}
                      >
                        <Typography>Ləğv et</Typography>
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        autoFocus
                        sx={{ width: "50%" }}
                        disabled={isSubmitting}
                      >
                        Tətbiq et
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
