/* eslint-disable prettier/prettier */
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Edit,
  Delete,
  Star,
  PersonSearch,
  RunningWithErrors
} from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import StructureSelectCard from "../../components/companyStructure/structureSelectCard";
import CheckboxInput from "../../components/layouts/checkbox/Checkbox";
import {
  BUTTON_STATE_ENUMS,
  STAFF_TIME,
  structureNamingsEnum,
} from "../../constants";
import { GENERAL_BLOCK, GENERAL_BUTTON, GENERAL_INPUT, LINK_CUSTOM } from "../../styled";
import style from "./structure.module.css"
import { enqueueSnackbar } from "notistack";
import * as structureServices from "../../services/structure/StructureServices";
import { removeStaff, removeEmployeeFromStaff } from "../../services/employees/EmployeeServices";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setTriggingRefresh } from "../../redux/features/slices/commonThings.slice";
import { updateStaff } from "../../services/staff/StaffServices";
import * as companyPartServices from "../../services/companyPart/CompanyPartServices";
import { getCommonData } from "../../api/api";

const StaffTable = ({
  isClickChangeApproveRequest,
  loadOptionsForCompanyParts,
  activePositions,
  setActivePositions,
  set_expanded_table,
  handleOpenStaffDialog,
  expanded_table,
  handleOpenOrderDialog,
  structureById,
  handleChangeStructureCheckbox,
  handleOpenEmployeeDialog,
  checkedItems,
  classes,
  deleteStaff,
  getStructure,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState({
    open: false,
    section: "",
  });
  const [supplementalWageGeneral, set_supplementalWageGeneral] = useState([]);
  const [new_position, set_new_position] = useState({
    max_salary: "",
    relId: "",
    position: "",
    // source: "",
  });
  // staff edit
  const [isStaffEdit, setIsStaffEdit] = useState({
    open: false,
    emp: "",
  });
  const [staffForm, setStaffForm] = useState({
    staff: "",
    salary: "",
    // supplementalWage: {
    //   name: "",
    //   value: ""
    // }
  });
  // console.log(staffForm)
  const isOpenEditMode = (sectionId) =>
    isEditMode.open && sectionId === isEditMode.section;

  const handleNewPosition = (sourceId, relId, expectedType) => {
    set_new_position({
      ...new_position,
      position: sourceId,
      relId,
      sourceType:
        expectedType === structureNamingsEnum.MANAGEMENT
          ? "management"
          : "part",
    });
  };

  const saveNewPosition = async (sourceId, relId, expectedType) => {
    if (new_position?.max_salary < 300) {
      enqueueSnackbar(`Minimum vəzifə tarif maaşı 300 \u20BC təşkil edir`, {
        variant: "error",
      });
      return;
    }
    try {
      const payload = {
        source: new_position?.position,
        sourceType: new_position?.sourceType,
        max_salary: new_position?.max_salary,
      };

      const data = await structureServices.addSourceToRel(
        new_position?.relId,
        payload
      );
      if (data) {
        let positions = data?.children?.filter(
          (ch) => ch?.child?.expectedType?.name === "position"
        );
        set_new_position({
          max_salary: "",
          id: null,
        });
        await getStructure();
        if (positions !== undefined) {
          setActivePositions({ ...activePositions, positions });
        }
        toast.success("Uğurla əlavə edildi!");
        getStructure();
        if (positions !== undefined) {
          setActivePositions({ ...activePositions, positions });
        }
      } else {
        toast.error("Hər hansı bir xəta baş verdi!");
      }
    } catch (error) {
      toast.error("Xəta baş verdi!");
      console.log("error: ", error);
    } finally {
    }
  };

  function validateNewPosition(e, relId, max_salary) {
    if (new_position?.relId !== relId) {
      set_new_position({
        ...new_position,
        max_salary: e.target.value,
        relId: null,
      });
    } else {
      set_new_position({
        ...new_position,
        max_salary: e.target.value,
      });
    }
  }

  const staffTime = STAFF_TIME;

  async function deletePosition(child) {
    let response;
    if (structureById?.recruiterStaff && structureById?.isVerified && child?.isAccepted) {
      try {
        response = await structureServices.deleteChildRel(child?._id);
        if (response) {
          enqueueSnackbar("Vəzifə uğurla silindi!", { variant: "success" });
          getStructure();
        }
      } catch (error) {
        enqueueSnackbar("Xəta baş verdi", { variant: "error" });
      }
    } else {
      try {
        response = await structureServices.deleteChild(child?._id, id);
        if (response) {
          enqueueSnackbar("Vəzifə uğurla silindi!", { variant: "success" });
          getStructure();
        }
      } catch (error) {
        if (error.message === "Request already exist") {
          enqueueSnackbar("Hazırda dəyişiklik sorğusu mövcuddur", {
            variant: "error",
          });
        } else enqueueSnackbar("Xəta baş verdi", { variant: "error" });
      }
    }
  }

  const updateAfterResponse = async (resp, childId) => {
    await getStructure();
    toast.success("Uğurla dəyişdirildi!");
  };

  const handleOnChangeStructurePositions = async (posId) => {
    try {
      const data = await structureServices.updateStructureChild({
        structureId: structureById?._id,
        payload: {
          relId: new_position.relId,
          partId: new_position.position,
          max_salary: new_position.max_salary,
        },
      });
      if (data) {
        getStructure();
        enqueueSnackbar("Dəyişikliklər sorğunu bitirdikdən sonra ştat cədvəlində əks olunacaq", { variant: "success" });
        setIsEditMode({
          open: false,
          section: "",
        });
      }
    } catch (error) {
      if (
        error?.response?.data?.errors[0]?.message ===
        "This part already used in this structure"
      ) {
        console.log("err", error);
        toast.error("Eyni adlı vəzifə bu strukturda mövcuddur!");
      } else if (error?.response?.data?.errors[0]?.message ===
        "Max salary can't be less than staff salary") {
        enqueueSnackbar("Maksimum əmək haqqı ştatın əmək haqqından az ola bilməz!", { variant: "error" });
      }
    }
  };


  async function deleteEmployeeFromStaff(staff_id, employee_id) {
    const resp = await removeEmployeeFromStaff(staff_id, employee_id);
    if (resp && Object.keys(resp).length > 0) {
      getStructure();
      toast.success("Əməkdaş ştatdan uğurla silindi!");
    }
  }

  const handlerUptdateStaffSubmit = async (staffId, position, posIndex) => {
    try {
      const { data } = await updateStaff({
        staffId,
        payload: {
          position,
          staffUnit: staffForm.staff,
          positionSalary: staffForm.salary,
          // supplementalWage: staffForm?.supplementalWage
        }
      });
      if (data) {
        setIsStaffEdit({ emp: "", open: false });
        setStaffForm({ salary: "", staff: "" });
        const newPositions = activePositions.positions.map((item, index) => {
          if (index === posIndex) {
            const child = { ...item?.child, staff: data };
            return { child: child, children: item.children };
          }
          return item;
        });

        setActivePositions((prev) => ({
          ...prev,
          positions: newPositions,
        }));
        enqueueSnackbar("Dəyişiklik yadda saxlanıldı", { variant: "success" });
      }
    } catch (error) {
      console.log("err", error);
      enqueueSnackbar("Xəta baş verdi", { variant: "error" });
    }
  };

  const [positon_load_options, set_positon_load_options] = useState();
  const [position_options, set_position_options] = useState({
    name: "",
    limit: 10,
    skip: 0,
    type: "",
    notUsed: null,
    parentRel: null,
    includedId: "",
  });

  let getCompanyPartPositions = useCallback(async () => {
    let response;
    response = await companyPartServices.getCompanyStructures(position_options);
    let final = {
      options: response?.parts.map((item) => ({
        value: item._id,
        // label: item.name + " - " + generateCapatilazeWord(item?.type?.name),
        label: item.name,
      })),
      // hasMore: _start < total - 1,
    };
    const uniqueList = final?.options.filter(
      (obj, index, self) =>
        index ===
        self.findIndex((t) => t.value === obj.value && t.label === obj.label)
    );
    final.options = uniqueList;
    set_positon_load_options(final);
  }, [position_options]);

  useEffect(() => {
    if (position_options?.type !== "") {
      getCompanyPartPositions();
    }
  }, [position_options?.type, position_options?.name, getCompanyPartPositions]);

  function handleClear(relId, e) {
    if (new_position?.relId !== relId && !isEditMode.open) {
      e.target.innerValue = "";
      set_new_position({
        ...new_position,
        relId: "",
        max_salary: "",
        source: null,
        position: "",
      });
    } else if (new_position?.relId !== relId) {
      setIsEditMode({
        open: false,
        section: "",
      });
    }
  }

  function newPosModes(sourceId, relId, expectedType) {
    if (isOpenEditMode(relId)) {
      set_new_position((prev) => ({
        ...prev,
        position: sourceId,
        relId: relId,
        // source: sourceId,
      }));
      return;
    } else {
      set_new_position({
        ...new_position,
        relId,
      });
      handleNewPosition(sourceId, relId, expectedType);
    }
  }

  function editStaff(i, p) {
    setIsStaffEdit({
      open: true,
      emp: i,
    });
    setStaffForm({
      salary: p?.positionSalary,
      staff: p?.staffUnit,
      // supplementalWage: {
      //   name: p?.supplementalWage?.name,
      //   value: p?.supplementalWage?.value
      // }
    })
    getSpecialFoodSupplements();
  }
  const getSpecialFoodSupplements = async () => {
    try {
      const response = await getCommonData("supplementalWageGeneral");
      console.log(response.data)
      set_supplementalWageGeneral(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Table
      style={{
        width: "90%",
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ backgroundColor: "#778DA9" }}
      >
        <TableCell
          component="th"
          scope="row"
          style={{ color: "#26405f", fontWeight: "bold" }}
        ></TableCell>
        {isClickChangeApproveRequest && (
          <TableCell
            component="th"
            scope="row"
            style={{ color: "#26405f", fontWeight: "bold" }}
          >
            Dəyişiləcək
          </TableCell>
        )}
        <TableCell
          align="left"
          style={{ color: "#335072", fontWeight: "bold" }}
        >
          Vəzifə
        </TableCell>
        <TableCell
          align="left"
          style={{ color: "#335072", fontWeight: "bold" }}
        >
          Vəzifə tarif maaşı
        </TableCell>
        <TableCell
          align="left"
          style={{ color: "#335072", fontWeight: "bold" }}
        >
          Ştat sayı
        </TableCell>
        <TableCell component="th" scope="row"></TableCell>
        <TableCell component="th" scope="row"></TableCell>
      </TableRow>
      {activePositions?.positions?.map((pos, index) => (
        pos?.child?.expectedType?.name === "position" &&
        <React.Fragment key={pos?.child?._id || index}>
          <TableRow
            style={{
              borderBottom: "unset",
              backgroundColor: "#415A77",
              height: !pos?.child?.name ? "70px" : "unset",
            }}
          >
            <TableCell
              style={{
                color: "#fff",
                fontWeight: "bold",
                width: "20px",
                padding: "6px 10px",
                border: activePositions?.positions?.length - 1 === index && 0,
              }}
            >
              {pos?.child?.staff?.length > 0 && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  color="inherit"
                  onClick={() => {
                    let arr = [...expanded_table?.index];
                    let item_index = arr.indexOf(index);
                    if (arr?.includes(index)) {
                      arr.splice(item_index, 1);
                    } else {
                      arr.push(index);
                    }
                    set_expanded_table({
                      ...expanded_table,
                      expanded: expanded_table?.expanded
                        ? true
                        : !expanded_table?.expanded,
                      index: arr,
                    });
                  }}
                >
                  {expanded_table?.expanded &&
                    expanded_table?.index?.includes(index) ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )}
                </IconButton>
              )}
            </TableCell>
            {isClickChangeApproveRequest && !pos?.child?.pendingChange && (
              <TableCell
                style={{
                  width: "30px",
                  border: activePositions?.positions?.length - 1 === index && 0,
                }}
              >
                <span className="d-inline-block">
                  <CheckboxInput
                    // labelContent={"Dəyişiləcək"}
                    id={pos?.child?._id}
                    name={pos?.child?.name}
                    onChange={handleChangeStructureCheckbox}
                    checked={checkedItems.some(
                      (checkedItem) => checkedItem?.id === pos?.child?._id
                    )}
                  />
                </span>
              </TableCell>
            )}
            <TableCell
              align="left"
              width="20%"
              style={{
                color: "#fff",
                padding: "6px 10px",
                border: activePositions?.positions?.length - 1 === index && 0,
              }}
            >
              {pos?.child?.name && !isOpenEditMode(pos?.child?._id) ? (
                pos?.child?.name
              ) : (
                <StructureSelectCard
                  isEditMode={isEditMode}
                  includedId={pos?.child?.part?._id}
                  options={positon_load_options}
                  value={new_position?.position}
                  handleClear={handleClear}
                  set_position_options={set_position_options}
                  set_new_position={set_new_position}
                  key={`${pos?.child?._id}${index}`}
                  classes={classes}
                  item={pos}
                  bgColor={"rgb(3 136 177 / 70%)"}
                  position={"relative"}
                  isOnlyUnused={true}
                  handleNewPosition={newPosModes}
                  loadOptionsForCompanyParts={(
                    _search,
                    loadedOptions,
                    item,
                    isOnlyUnused,
                    isBaseLevel,
                    childData
                  ) =>
                    loadOptionsForCompanyParts(
                      _search,
                      loadedOptions,
                      item,
                      isOnlyUnused,
                      isBaseLevel,
                      childData,
                      pos?.child?.part?._id
                    )
                  }
                  handleSaveCompanyParts={(sourceId, relId, expectedType) => {
                    if (isOpenEditMode(pos?.child?._id)) {
                      set_new_position((prev) => ({
                        ...prev,
                        position: sourceId,
                        relId: relId,
                        // source: sourceId,
                      }));
                      return;
                    } else {
                      set_new_position({
                        ...new_position,
                        relId,
                      });
                      handleNewPosition(sourceId, relId, expectedType);
                    }
                  }}
                  isContentCenter={true}
                  type="table"
                />
              )}
            </TableCell>
            <TableCell
              align="left"
              style={{
                color: "#fff",
                padding: "6px 10px",
                border: activePositions?.positions?.length - 1 === index && 0,
              }}
            >
              <div className="d-flex">
                {pos?.child?.max_salary && !isOpenEditMode(pos?.child?._id) ? (
                  <div>{pos?.child?.max_salary?.toFixed(2)} &#8380;</div>
                ) : (
                  <div className="position-relative">
                    <GENERAL_INPUT
                      $height="36px"
                      minLength={300}
                      $brad="5px"
                      $bord="1px solid #cccccc"
                      $pad="0 0 0 10px"
                      placeholder="Maks. maaş"
                      type="number"
                      min={0}
                      disabled={new_position?.relId === "" && true}
                      // disabled={(isEditMode.open && form?.position === "") ? true : new_position?.relId === "" ? true : false}
                      id={pos?.child?._id}
                      onFocus={(e) => handleClear(pos?.child?._id, e)}
                      value={
                        isOpenEditMode(pos?.child?._id)
                          ? new_position.max_salary
                          : new_position?.relId === pos?.child?._id &&
                          new_position.max_salary
                      }
                      onChange={(e) => {
                        // if (e.target.value > pos?.child?.max_salary) {
                        //   enqueueSnackbar("Məbləğ maksimum əmək haqqından çox ola bilməz", { variant: "error" })
                        //   return
                        // }
                        isOpenEditMode(pos?.child?._id)
                          ? set_new_position((prev) => ({
                            ...prev,
                            max_salary: e.target.value,
                          }))
                          : validateNewPosition(e, pos?.child?._id, pos?.child?.max_salary)
                      }
                      }
                    // type="text"
                    />
                    <GENERAL_BLOCK f_size="10px" className="position-absolute">
                      {" "}
                      Minimum vəzifə tarif maaşı 300 &#8380;
                    </GENERAL_BLOCK>
                  </div>
                )}
                {!pos?.child?.name &&
                  new_position?.relId === pos?.child?._id &&
                  new_position?.max_salary >= 300 &&
                  new_position?.relId && (
                    <Button
                      variant="outlined"
                      onClick={async (e) => {
                        if (!isOpenEditMode(pos?.child?._id)) {
                          saveNewPosition(e);
                          return;
                        }
                        await handleOnChangeStructurePositions(pos?.child?._id);
                        // setForm({ max_salary: "" });
                        set_new_position((prev) => ({
                          ...prev,
                          max_salary: "",
                        }));
                      }}
                      className="ms-3"
                      sx={{ height: "36px" }}
                    >
                      Yadda saxla
                    </Button>
                  )}
                {isOpenEditMode(pos?.child?._id) && (
                  <Button
                    variant="outlined"
                    onClick={async (e) => {
                      await handleOnChangeStructurePositions(pos?.child?._id);
                      // setForm({ max_salary: "" });
                      set_new_position((prev) => ({
                        ...prev,
                        max_salary: "",
                      }));
                    }}
                    className="ms-3"
                    sx={{ height: "36px" }}
                    disabled={
                      (new_position?.max_salary === "" &&
                        new_position?.position === "") ||
                      new_position?.position === undefined
                    }
                  >
                    Yadda saxla
                  </Button>
                )}
                {isOpenEditMode(pos?.child?._id) && (
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => {
                      setIsEditMode({
                        open: false,
                        section: "",
                      });
                      set_new_position((prev) => ({
                        ...prev,
                        max_salary: "",
                        position: "",
                        relId: "",
                      }));
                    }}
                    className="ms-3"
                    sx={{ height: "36px" }}
                  // disabled={positionBtn?.state}
                  >
                    İmtina
                  </Button>
                )}
              </div>
            </TableCell>
            <TableCell
              align="left"
              style={{
                color: "#fff",
                padding: "6px 10px",
                border: activePositions?.positions?.length - 1 === index && 0,
              }}
            >
              {pos?.child?.staff?.length}
            </TableCell>
            <TableCell
              align="right"
              style={{
                color: "#fff",
                padding: "6px 10px",
                border: activePositions?.positions?.length - 1 === index && 0,
              }}
              colSpan={7}
            >
              <div className="d-flex align-items-center justify-content-end">
                {(!structureById?.recruiterStaff || pos?.child?.editable) &&
                  pos?.child?.name && (
                    <div>
                      <button
                        className={control_button}
                        onClick={() => handleOpenStaffDialog(pos?.child)}
                      >Ştat əlavə et</button>
                    </div>
                    // <GENERAL_BUTTON
                    //   bg_color="#1B263B"
                    //   hover_bg="#778da9"
                    //   hei="30px"
                    //   bord="0"
                    //   clr="#fff"
                    //   $radius="5px"
                    //   onClick={() => handleOpenStaffDialog(pos?.child)}
                    // >
                    //   Ştat əlavə et

                    // </GENERAL_BUTTON>
                  )}
                {pos?.child?.docModel !== null && pos?.child?.editable &&
                  !isOpenEditMode(pos?.child?._id) && (
                    <Tooltip title="Vəzifəni redaktə et">
                      <div className="ms-2">
                        <button
                          className={control_button}
                          onClick={() => {
                            setIsEditMode({ open: true, section: pos?.child?._id });
                            set_new_position((prev) => ({
                              ...prev,
                              max_salary: pos?.child?.max_salary,
                              position: pos?.child?.part?._id,
                              relId: pos?.child?._id,
                            }));
                          }}
                        ><Edit
                          // style={{ width: "14px", height: "17px" }} 
                          />
                        </button>
                      </div>
                    </Tooltip>
                  )}
                {!pos?.child?.staff?.length && pos?.child?.editable && (
                  <Tooltip title="Vəzifəni sil">
                    <div className="ms-2">
                      <button
                        className={control_button}
                        onClick={() => deletePosition(pos?.child)}
                      ><Delete
                        // style={{ width: "14px", height: "17px" }}
                        /></button>
                    </div>
                  </Tooltip>
                )}
              </div>
            </TableCell>
            {/* <TableCell
              component="th"
              style={{
                color: "#fff",
                padding: "6px 10px",
                border: activePositions?.positions?.length - 1 === index && 0,
              }}
              scope="row"
            ></TableCell> */}
          </TableRow>
          {pos?.child?.staff?.length > 0 && (
            <TableRow style={{ backgroundColor: "#778DA9" }}>
              <TableCell style={{ padding: 0 }} colSpan={7}>
                {/* border: 0 */}
                <Collapse
                  in={
                    expanded_table?.expanded &&
                    expanded_table?.index?.includes(index)
                  }
                  timeout="auto"
                  unmountOnExit
                >
                  {/* && expanded_table?.index === index */}
                  <Box>
                    <Table aria-label="purchases">
                      <TableRow>
                        {/* <TableCell></TableCell> */}
                        <TableCell
                          align="center"
                          component="th"
                          style={{
                            color: "#000",
                            fontWeight: "bold",
                            width: "3%",
                            padding: "6px 10px",
                          }}
                          colSpan={4}
                        >
                          Tabel No.
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          style={{
                            color: "#000",
                            fontWeight: "bold",
                            width: "2%",
                            padding: "6px 10px",
                          }}
                          colSpan={7}
                        >
                          A.S.A
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          style={{
                            color: "#000",
                            fontWeight: "bold",
                            width: "5%",
                            padding: "6px 10px",
                          }}
                          colSpan={1}
                        >
                          Əsas/əlavə iş yeri
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          style={{
                            color: "#000",
                            fontWeight: "bold",
                            width: "4%",
                            padding: "6px 10px",
                          }}
                          colSpan={1}
                        >
                          Ştat Vahidi
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          style={{
                            color: "#000",
                            fontWeight: "bold",
                            width: "1%",
                            padding: "6px 10px",
                          }}
                          colSpan={1}
                        >
                          Ə.M. Əməkhaqqı
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          style={{
                            color: "#000",
                            fontWeight: "bold",
                            width: "4%",
                            padding: "6px 10px",
                          }}
                          colSpan={2}
                        >
                          Ə.M. Əməkhaqqına əlavə(lər)
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          style={{
                            color: "#000",
                            fontWeight: "bold",
                            width: "4%",
                            padding: "6px 10px",
                          }}
                          colSpan={3}
                        >
                          Təsdiqləmə matrisi
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          style={{
                            color: "#000",
                            fontWeight: "bold",
                            width: "7%",
                            padding: "6px 10px",
                          }}
                          colSpan={3}
                        >
                          Status
                        </TableCell>
                      </TableRow>

                      {pos?.child?.staff?.map((p, i) => (

                        <TableRow
                          className="position-relative"
                          key={p._id}
                          style={
                            isStaffEdit.open && isStaffEdit.emp === i
                              ? {
                                backgroundColor: "#233044",
                                // opacity: 0.5,
                              }
                              : {}
                          }
                        >
                          <TableCell
                            style={{
                              color: "#000",
                              border: i === pos?.child?.staff?.length - 1 && 0,
                            }}
                            align="center"
                            colSpan={4}
                          >
                            {structureById?.recruiterStaff?._id === p?._id &&
                              <Tooltip title={structureById?.recruiterStaff?._id === p?._id ? "Bu əməkdaş cari strukturun işə götürənidir" : ""}>
                                <PersonSearch />
                              </Tooltip>
                            }
                            {p?.employee?.[0]?.tableNumber ?? "----"}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#000",
                              width: "200px",
                              border: i === pos?.child?.staff?.length - 1 && 0,
                            }}
                            align="center"
                            colspan={7}
                          >
                            {p?.employee?.length
                              ? `${p?.employee?.[0]?.firstname} ${p?.employee?.[0]?.lastname} ${p?.employee?.[0]?.fathername}`
                              : "Vakant"}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#000",
                              width: "120px",
                              border: i === pos?.child?.staff?.length - 1 && 0,
                            }}
                            align="center"
                            colSpan={1}
                          >
                            ---
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#000",
                              border: i === pos?.child?.staff?.length - 1 && 0,
                            }}
                            align="center"
                            colSpan={1}
                          >
                            {isStaffEdit.open && isStaffEdit.emp === i ? (
                              <>
                                <InputLabel
                                  style={{ color: 'transparent' }}
                                >
                                  hidden
                                </InputLabel>
                                <FormControl variant="standard" fullWidth>
                                  <Select
                                    placeholder="Ştatı vahidi seçin"
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    name="staffUnit"
                                    label={`Ştatı vahidi seçin*`}
                                    onChange={(e) => {
                                      const max_salary = pos?.child?.max_salary;
                                      const limit = +max_salary * +e.target.value;

                                      setStaffForm((prev) => ({
                                        salary:
                                          prev.salary > limit
                                            ? limit
                                            : prev.salary,
                                        staff: e.target.value,
                                      }));
                                    }}
                                    // onBlur={formik.handleBlur}
                                    // error={Boolean(
                                    //   formik.touched?.staffUnit &&
                                    //     formik.errors?.staffUnit
                                    // )}
                                    value={staffForm.staff}
                                  // className={
                                  //   Boolean(
                                  //     formik.touched?.staffUnit &&
                                  //       formik.errors?.staffUnit
                                  //   )  
                                  //     ? "error"
                                  //     : ""
                                  // }
                                  >
                                    {staffTime.length &&
                                      staffTime.map((time, index) => (
                                        <MenuItem
                                          key={index + 1}
                                          value={time.timeId}
                                        >
                                          {time.content}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                  {/* {formik.touched?.staffUnit && (
                                  <FormHelperText>
                                    <span
                                      style={
                                        formik.touched?.staffUnit
                                          ? { color: "#f44336" }
                                          : null
                                      }
                                    >
                                      {formik.errors?.staffUnit}
                                    </span>
                                  </FormHelperText>
                                )} */}
                                </FormControl>
                              </>
                            ) : (
                              p?.staffUnit ?? "----"
                            )}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#000",
                              border: i === pos?.child?.staff?.length - 1 && 0,
                            }}
                            align="center"
                            colSpan={1}
                          >
                            {isStaffEdit.open && isStaffEdit.emp === i ? (
                              <>
                                <InputLabel
                                  style={{ color: 'transparent' }}
                                >
                                  hidden
                                </InputLabel>
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  placeholder="Maks. maaş"
                                  type="decimal"
                                  min={0}
                                  minLength={300}
                                  value={staffForm.salary}
                                  onChange={(e) => {
                                    const max_salary = pos?.child?.max_salary;
                                    const limit = +max_salary * +staffForm.staff;

                                    const value =
                                      e.target.value > limit
                                        ? limit
                                        : +e.target.value;

                                    setStaffForm((prev) => ({
                                      ...prev,
                                      salary: value,
                                    }));
                                  }}
                                />
                              </>
                            ) :
                              <>{p?.positionSalary ? `${p?.positionSalary} \u20BC` : "---"}</>
                            }
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#000",
                              width: "50px",
                              border: i === pos?.child?.staff?.length - 1 && 0,
                            }}
                            align="center"
                            colSpan={1}
                          >
                            {/* {isStaffEdit.open && isStaffEdit.emp === i && */}
                            {/* // ? 
                            // (
                            //   <div>
                            //     <InputLabel */}
                            {/* //       htmlFor="max-width"
                            //       id="demo-simple-select-standard-label"
                            //     >
                            //       Əməkhaqqına əlavənin səbəbi və dəyəri
                            //     </InputLabel>
                            //     <div className="d-flex">
                            //       { */}
                            {/* //         supplementalWageGeneral?.length &&
                            //         <FormControl variant="standard" fullWidth>
                            //           <Select
                            //             labelId="demo-simple-select-standard-label" */}
                            {/* //             id="demo-simple-select-standard"
                            //             // name={`supplementalWage.name`}
                            //             value={staffForm.supplementalWage?.name || "Seçin"}
                            //             onChange={(e) => {
                            //               setStaffForm((prev) => ({
                            //                 ...prev,
                            //                 supplementalWage: {
                            //                   ...prev?.supplementalWage,
                            //                   name: e.target.value
                            //                 },
                            //               }));
                            //             }}
                            //           >
                            //             {supplementalWageGeneral?.length &&
                            //               supplementalWageGeneral?.map((item, i) => (
                            //                 <MenuItem key={i} value={item}>
                            //                   {item}
                            //                 </MenuItem>
                            //               ))}
                            //           </Select>
                            //         </FormControl>
                            //       }
                            //       <FormControl variant="standard" fullWidth className="ms-2">
                            //         <TextField
                            //           variant="standard"
                            //           fullWidth
                            //           placeholder="Maaş əlavəsi"
                            //           type="decimal"
                            //           min={0}
                            //           value={staffForm.supplementalWage?.value}
                            //           onChange={(e) => {
                            //             setStaffForm((prev) => ({
                            //               ...prev,
                            //               supplementalWage: {
                            //                 ...prev?.supplementalWage,
                            //                 value: +e.target.value
                            //               },
                            //             }));
                            //           }}
                            //         />
                            //       </FormControl>
                            //     </div>
                            //   </div>
                            // ) : */}
                            <>{p?.supplementalWage?.value ? `${p?.supplementalWage?.value} \u20BC` : "---"}</>
                            {/* // } */}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#000",
                              width: "50px",
                              border: i === pos?.child?.staff?.length - 1 && 0,
                            }}
                            align="center"
                            colSpan={3}
                          >
                            {p.isApprover ? "Daxildir" : "Daxil deyil"}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#000",
                              border: i === pos?.child?.staff?.length - 1 && 0,
                              width: '10%'
                            }}
                            align="center"
                            colSpan={3}
                          >
                            <div
                              className="d-flex align-items-center justify-content-end"
                            >
                              {isStaffEdit.emp === i && isStaffEdit.open && (
                                <Button
                                  variant="outlined"
                                  onClick={async (e) => {
                                    await handlerUptdateStaffSubmit(
                                      p?._id,
                                      p?.position?._id,
                                      index
                                    );
                                  }}
                                  className="ms-3"
                                  sx={{ height: "36px" }}
                                  disabled={
                                    !(staffForm?.salary || staffForm?.staff)
                                  }
                                >
                                  Yadda saxla
                                </Button>
                              )}
                              {isStaffEdit.emp === i && isStaffEdit.open && (
                                <Button
                                  color="error"
                                  variant="outlined"
                                  onClick={() => {
                                    setIsStaffEdit({
                                      open: false,
                                      emp: "",
                                    });
                                    setStaffForm({ salary: "", staff: "" });
                                  }}
                                  className="ms-3"
                                  sx={{ height: "36px" }}
                                >
                                  İmtina
                                </Button>
                              )}
                              {p?.isContractUpdatable && (
                                <Tooltip title="Ə.M redaktə et">
                                  <div className="me-2">
                                    <button
                                      className={control_button}
                                    ><a href={`/labour-contract/edit/${p?._id}-${p?.contract?._id}`} target="_blank" rel="noreferrer"
                                      className="w-100 d-block"
                                    ><Edit
                                        // style={{ width: "14px", height: "17px" }} 
                                        />
                                      </a></button>
                                  </div>
                                </Tooltip>
                              )}
                              {isStaffEdit.emp !== i &&
                                (!structureById?.recruiterStaff ||
                                  !pos?.child?.editable) &&
                                (p.status ===
                                  BUTTON_STATE_ENUMS.PENDING_CONTRACT.name ? (
                                  <button
                                    className={`${status_button(p?.status)}`}
                                  >
                                    <a href={`/labour-contract/${p?._id}`} target="_blank" rel="noreferrer"
                                      className="w-100 d-block"
                                    >  <span>
                                        {
                                          BUTTON_STATE_ENUMS[
                                            p?.status?.toUpperCase()
                                          ]?.content
                                        }
                                      </span>
                                    </a>
                                  </button>
                                ) : (
                                  <button
                                    className={`${status_button(p?.status)}`}
                                    onClick={() => {
                                      switch (p.status) {
                                        case BUTTON_STATE_ENUMS.EMPTY.name:
                                          return handleOpenEmployeeDialog(
                                            p?._id,
                                            pos?.child?._id,
                                            p?.staffUnit,
                                            p?.supplementalWage
                                          );
                                        case BUTTON_STATE_ENUMS.PENDING_ORDER
                                          .name:
                                          handleOpenOrderDialog(p?._id);
                                          break;
                                        default:
                                          break;
                                      }
                                    }}
                                  >
                                    <span>
                                      {
                                        BUTTON_STATE_ENUMS[
                                          p?.status?.toUpperCase()
                                        ]?.content
                                      }
                                    </span>
                                  </button>
                                ))}
                              {p?.status === "empty" && pos?.child?.editable && structureById?.isVerified &&
                                (
                                  <GENERAL_BLOCK
                                    clr="lightgray"
                                    className="ms-2 d-flex align-items-center justify-content-center"
                                  >
                                    Yeni əməkdaş təyin etmək üçün zəhmət olmasa dəyişiklikləri bitirin
                                  </GENERAL_BLOCK>
                                )}
                              {(p?.status === "empty" &&
                                (!isStaffEdit.open || isStaffEdit.emp !== i)) && (
                                  <Tooltip title="Ştatı redaktə et">
                                    <div className="ms-2">
                                      <button
                                        className={control_button}
                                        onClick={() => {
                                          p?.status === "empty" &&
                                            editStaff(i, p);
                                        }}
                                      ><Edit
                                        // style={{ width: "14px", height: "17px" }}
                                        /></button>
                                    </div>
                                  </Tooltip>
                                )}
                              {(p?.status === "empty" && !isStaffEdit.open) || p?.isEmployeeDeletable ? (
                                <Tooltip title={p?.status === "empty" ? "Ştatı sil" : "Əməkdaşı sil"}>
                                  <div className="ms-2">
                                    <button
                                      className={control_button}
                                      onClick={async () => {
                                        p?.status === "empty" ?
                                          await deleteStaff(p) :
                                          deleteEmployeeFromStaff(p?._id, p?.employee?.[0]?._id)
                                      }}
                                    ><Delete
                                      // style={{ width: "14px", height: "17px" }}
                                      /></button>
                                  </div>
                                </Tooltip>
                              ) : null}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          )
          }
        </React.Fragment >
      ))}
    </Table >
  );
};

export default StaffTable;

const control_button = [
  style?.custom_button,
  style?.primary
].join(" ");
const status_button = (status) => {
  let color = "";
  switch (status) {
    case BUTTON_STATE_ENUMS.CONTRACT_IN_DRAFT.name:
      color = style?.color5252ab75;
      break;
    case BUTTON_STATE_ENUMS.ACTIVE.name:
      color = style?.color0e8b00;
      break;
    case BUTTON_STATE_ENUMS.PENDING_CONTRACT.name:
      color = style?.colorBEAEE2;
      break;
    case BUTTON_STATE_ENUMS.EMPTY.name:
      color = style?.color1B263B;
      break;
    case BUTTON_STATE_ENUMS.PENDING_ORDER.name:
      color = style?.color6dbab0;
      break;
    case BUTTON_STATE_ENUMS
      .REJECTED_APPLICATION.name:
      color = style?.colorc91313
      break;
    default:
      break;
  }
  return [style?.custom_button, color].join(" ");
}