import React, { useState } from "react";
import CustomAccordion from "../../../../components/ui/Accordion/Accordion";
import {
  AccordionDetails,
  AccordionSummary,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { Field } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

const LabourPeriod = ({ setFieldValue, selectedValue, values }) => {
  const [inputValue, setInputValue] = useState("");

  const handleAddChip = (setFieldValue, values) => {
    if (inputValue.trim() !== "") {
      setFieldValue("labourPeriod.laborFunctions", [
        ...values.labourPeriod.laborFunctions,
        inputValue.trim(),
      ]);
      setInputValue("");
    }
  };

  const handleDeleteChip = (chipToDelete, setFieldValue, values) => {
    setFieldValue(
      "labourPeriod.laborFunctions",
      values.labourPeriod.laborFunctions.filter((chip) => chip !== chipToDelete)
    );
  };
  return (
    <>
      <CustomAccordion sx={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <h6>Əmək müqaviləsininin müddəti</h6>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Grid sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel>Əmək Müqaviləsi növü</InputLabel>
                <Field
                  as={Select}
                  label="Əmək Müqaviləsi növü"
                  defaultValue=""
                  fullWidth
                  name="labourPeriod.contractPeriodType"
                  onChange={(event) => {
                    setFieldValue(
                      "labourPeriod.contractPeriodType",
                      event.target.value
                    );
                  }}
                >
                  {selectedValue?.contractPeriodType &&
                    selectedValue?.contractPeriodType.map((item, index) => {
                      return (
                        <MenuItem
                          key={`${item?.value}${index}`}
                          value={item?.value}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                </Field>
              </FormControl>
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                gap: "5px",
              }}
            >
              <TextField
                type="number"
                label="Sınaq müddəti"
                defaultValue=""
                fullWidth
                name="labourPeriod.probationPeriod"
                onChange={(event) => {
                  setFieldValue(
                    "labourPeriod.probationPeriod",
                    event.target.value
                  );
                }}
              />
              <FormControl fullWidth>
                <InputLabel>Sınaq müddəti növü</InputLabel>
                <Field
                  as={Select}
                  label="Sınaq müddəti növü"
                  defaultValue=""
                  fullWidth
                  name="labourPeriod.probationPeriodType"
                  onChange={(event) => {
                    setFieldValue(
                      "labourPeriod.probationPeriodType",
                      event.target.value
                    );
                  }}
                >
                  {selectedValue?.probationPeriodType &&
                    selectedValue?.probationPeriodType.map((item, index) => {
                      return (
                        <MenuItem
                          key={`${item?.value}${index}`}
                          value={item?.value}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                </Field>
              </FormControl>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <Field name={`labourPeriod.contractStartDate`}>
                {({ field, form }) => (
                  <DatePicker
                    {...field}
                    label="Əmək Müqaviləsi başlama tarixi"
                    inputFormat="dd/MM/yyyy"
                    value={field.value || null}
                    onChange={(date) => {
                      if (date instanceof Date && !isNaN(date)) {
                        form.setFieldValue(
                          `labourPeriod.contractStartDate`,
                          date
                        );
                      }
                    }}
                    maxDate={new Date()}
                    minDate={new Date(1940, 0, 1)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                )}
              </Field>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <Field name={`labourPeriod.contractEndDate`}>
                {({ field, form }) => (
                  <DatePicker
                    {...field}
                    label="Əmək Müqaviləsi bitmə tarixi"
                    inputFormat="dd/MM/yyyy"
                    value={field.value || null}
                    onChange={(date) => {
                      if (date instanceof Date && !isNaN(date)) {
                        form.setFieldValue(
                          `labourPeriod.contractEndDate`,
                          date
                        );
                      }
                    }}
                    // maxDate={new Date()}
                    minDate={new Date(1940, 0, 1)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                )}
              </Field>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <Field name={`labourPeriod.jobStartDate`}>
                {({ field, form }) => (
                  <DatePicker
                    {...field}
                    label="Əməkdaşın işə başlama tarixi"
                    inputFormat="dd/MM/yyyy"
                    value={field.value || null}
                    onChange={(date) => {
                      if (date instanceof Date && !isNaN(date)) {
                        form.setFieldValue(`labourPeriod.jobStartDate`, date);
                      }
                    }}
                    // maxDate={new Date()}
                    minDate={new Date(1940, 0, 1)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                )}
              </Field>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel>Müddətli bağlanma səbəbi</InputLabel>
                <Field
                  as={Select}
                  label="Müddətli bağlanma səbəbi"
                  defaultValue=""
                  fullWidth
                  name="labourPeriod.reasonOfTemporaryContract"
                  onChange={(event) => {
                    setFieldValue(
                      "labourPeriod.reasonOfTemporaryContract",
                      event.target.value
                    );
                  }}
                >
                  {selectedValue?.reasonOfTemporaryContract &&
                    selectedValue?.reasonOfTemporaryContract.map((item, i) => (
                      <MenuItem key={`${item.value}${i}`} value={item.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
            </Grid>
            <>
              <TextField
                type="text"
                label="İşçi aşağıdakı əmək funksiyasının icrasını öhdəsinə götürür"
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
                fullWidth
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    handleAddChip(setFieldValue, values);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => handleAddChip(setFieldValue, values)}
                      disabled={!inputValue.trim()}
                    >
                      <AddIcon />
                    </IconButton>
                  ),
                }}
              />

              {/* Render chips */}
              <Paper
                component="ul"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "8px",
                  marginTop: "16px",
                }}
              >
                {values.labourPeriod.laborFunctions.map((func, index) => (
                  <li key={index} style={{ margin: "4px" }}>
                    <Chip
                      label={func}
                      onDelete={() =>
                        handleDeleteChip(func, setFieldValue, values)
                      }
                      deleteIcon={<CloseIcon />}
                    />
                  </li>
                ))}
              </Paper>
            </>
          </Grid>
        </AccordionDetails>
      </CustomAccordion>
    </>
  );
};

export default LabourPeriod;
