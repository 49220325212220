import React, { useEffect, useState } from "react";
import CustomAccordion from "../../../../components/ui/Accordion/Accordion";
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Field } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getCommonData } from "../../../../api/api";
import NewCommonDataDialog from "../../../../components/employees/NewCommonDataDialog";
const Wage = ({ setFieldValue, selectedValue, values, weekDays }) => {
  const [supplementalWageLabourItems, setSupplementalWageLabourItems] =
    useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const banks = [
    { name: "Azərbaycan Beynəlxalq Bankı", voen: "9900001881", code: "805250" },
    { name: "Accessbank", voen: "1400057421", code: "505000" },
    { name: "AFB Bank", voen: "1301703781", code: "503217" },
    { name: "Azərbaycan Sənayə Bankı", voen: "9900007981", code: "509664" },
    { name: "Azər-Türk bankı", voen: "9900006111", code: "507699" },
    { name: "Bank Avrasiya", voen: "1700792251", code: "505129" },
    { name: "Bank of Baku", voen: "1700038881", code: "506924" },
    { name: "BTB Bank", voen: "1302164881", code: "501145" },
    { name: "Bank Respublika", voen: "9900001901", code: "505668" },
    { name: "Bank VTB (Azərbaycan)", voen: "1400117231", code: "506623" },
    { name: "Expressbank", voen: "1500031691", code: "505099" },
    { name: "Kapital Bank", voen: "9900003611", code: "200004" },
    { name: "Melli İran Bankı ( Bakı )", voen: "1300036291", code: "509761" },
    { name: "Muğanbank", voen: "1400122681", code: "506162" },
    { name: "Naxçıvanbank", voen: "0200432771", code: "505152" },
    { name: "YELO Bank", voen: "9900014901", code: "507064" },
    { name: "PAŞA Bank", voen: "1700767721", code: "505141" },
    { name: "Rabitəbank", voen: "9900001061", code: "506399" },
    { name: "Turanbank", voen: "1300016391", code: "507462" },
    { name: "Unibank", voen: "1300017201", code: "505754" },
    { name: "Xalq Bank", voen: "2000296061", code: "505055" },
    { name: "Yapı Kredi Bank Azərbaycan", voen: "9900009021", code: "509987" },
    { name: "Ziraat Bank Azərbaycan", voen: "1303953611", code: "512372" },
  ];
  const handleInventory = async () => {
    const supplementalWageLabourItemsResponse = await getCommonData(
      "supplementalWageLabourItems"
    );
    setSupplementalWageLabourItems(supplementalWageLabourItemsResponse.data);
  };

  useEffect(() => {
    handleInventory();
  }, [submitted]);
  const groupedItems = supplementalWageLabourItems.reduce((acc, item) => {
    let parent = item?.parent.toLocaleUpperCase();
    if (!acc[parent]) {
      acc[parent] = [];
    }
    acc[parent].push(item);
    return acc;
  }, {});
  return (
    <>
      <CustomAccordion sx={{ width: "100%" }}>
        <AccordionSummary
          // sx={{ border: "1px solid #56606F", borderRadius: "4px" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <h6>Əmək haqqı üzrə</h6>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Grid sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel>Əməyin ödənilməsi</InputLabel>
                <Field
                  as={Select}
                  label="Əməyin ödənilməsi"
                  defaultValue=""
                  fullWidth
                  name="wage.paymentOfWork"
                  onChange={(event) => {
                    setFieldValue("wage.paymentOfWork", event.target.value);
                  }}
                >
                  {selectedValue?.paymentOfWork &&
                    selectedValue?.paymentOfWork.map((item, i) => (
                      <MenuItem key={`${item.value}${i}`} value={item.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id={`wage.workingCondition`}>
                  Əmək şəraiti
                </InputLabel>
                <Field
                  as={Select}
                  name="wage.workingCondition"
                  label="Əmək şəraiti"
                  value={values.wage.workingCondition || ""}
                  onChange={(e) => {
                    const selectedItem = e.target.value;
                    setFieldValue("wage.workingCondition", selectedItem);
                  }}
                  defaultValue=""
                  fullWidth
                >
                  {Object.entries(groupedItems).flatMap(
                    ([parent, items], index) => [
                      <MenuItem key={`parent-${index}`} disabled>
                        {parent}
                      </MenuItem>,
                      ...items.map((item, subIndex) => (
                        <MenuItem key={`item-${subIndex}`} value={item}>
                          {" "}
                          {item.name}
                        </MenuItem>
                      )),
                    ]
                  )}
                  <Button
                    sx={{
                      mt: 3,
                      ml: 3,
                    }}
                    variant="outlined"
                    onClick={() => {
                      setOpenAddDialog(true);
                      setSelectedType([
                        "supplementalWageLabourItems",
                        { name: "İş şəraiti əlavə et" },
                      ]);
                    }}
                  >
                    Əlavə et
                  </Button>
                </Field>
              </FormControl>
            </Grid>

            <TextField
              type="number"
              label="Vəzifə/tarif maaşı"
              defaultValue=""
              fullWidth
              name="wage.wage"
              onChange={(event) => {
                setFieldValue("wage.wage", event.target.value);
              }}
            />
            <Grid sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel>Verilən mükafat və dövrü</InputLabel>
                <Field
                  as={Select}
                  label="Verilən mükafat və dövrü"
                  defaultValue=""
                  fullWidth
                  name="wage.bonusPeriod"
                  onChange={(event) => {
                    setFieldValue("wage.bonusPeriod", event.target.value);
                  }}
                >
                  {selectedValue?.bonusPeriod &&
                    selectedValue?.bonusPeriod.map((item, i) => (
                      <MenuItem key={`${item.value}${i}`} value={item.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
            </Grid>
            <TextField
              type="number"
              label="Verilən mükafat (məbləğ)"
              defaultValue=""
              fullWidth
              name="wage.givenBonus"
              onChange={(event) => {
                setFieldValue("wage.givenBonus", event.target.value);
              }}
            />
            <TextField
              type="number"
              label="Natamam iş günü (saat)"
              fullWidth
              name="wage.partTimeWorkHour"
              value={values.wage.partTimeWorkHour || ""}
              onChange={(event) => {
                const value = event.target.value;
                if (value <= 7 && value >= 0) {
                  setFieldValue("wage.partTimeWorkHour", value);
                } else {
                  setFieldValue("wage.partTimeWorkHour", "");
                }
              }}
            />

            <TextField
              type="number"
              label="İş vaxtından artıq işlədiyinə görə"
              defaultValue=""
              fullWidth
              name="wage.overtime"
              onChange={(event) => {
                setFieldValue("wage.overtime", event.target.value);
              }}
            />
            <FormControl fullWidth>
              <InputLabel>Əmək haqqının köçürüldüyü bankın adı</InputLabel>
              <Field
                as={Select}
                label="Əmək haqqının köçürüldüyü bankın adı"
                // multiple
                fullWidth
                name="wage.selectedBank"
                // value={values.workVacationTime.offDays}
                onChange={(event) => {
                  setFieldValue("wage.selectedBank", event.target.value);
                }}
                // renderValue={(selected) =>
                //   selected
                //     .map(
                //       (val) =>
                //         weekDays.find((day) => day.key === val)
                //           ?.name
                //     )
                //     .join(", ")
                // }
              >
                {banks.map((bank, index) => (
                  <MenuItem key={`${bank.code}${index}`} value={bank.code}>
                    {bank.name}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            <TextField
              type="text"
              label="Əmək haqqının ödənilməsi barədə digər şərtlər"
              defaultValue=""
              fullWidth
              name="wage.conditionsOfSalaryPayment"
              onChange={(event) => {
                setFieldValue(
                  "wage.conditionsOfSalaryPayment",
                  event.target.value
                );
              }}
            />
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel>Ödənilmə müddətini seçin</InputLabel>
                <Field
                  as={Select}
                  label="Ödənilmə müddətini seçin"
                  defaultValue=""
                  fullWidth
                  name="wage.paymentPeriod"
                  onChange={(event) => {
                    setFieldValue("wage.paymentPeriod", event.target.value);
                  }}
                >
                  {selectedValue?.paymentPeriod &&
                    selectedValue?.paymentPeriod.map((item, i) => (
                      <MenuItem key={`${item.value}${i}`} value={item.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
              {values?.wage?.paymentPeriod === 2 ? (
                <FormControl fullWidth>
                  <InputLabel>1-ci ödənişin tarixi</InputLabel>
                  <Field
                    as={Select}
                    label="1-ci ödənişin tarixi"
                    defaultValue=""
                    fullWidth
                    name="wage.paymentPeriodDate"
                    onChange={(event) => {
                      setFieldValue(
                        "wage.paymentPeriodDate",
                        event.target.value
                      );
                    }}
                  >
                    {weekDays &&
                      weekDays.map((item, i) => (
                        <MenuItem key={`${item.key}${i}`} value={item.key}>
                          {item?.name}
                        </MenuItem>
                      ))}
                  </Field>
                </FormControl>
              ) : (
                <TextField
                  type="number"
                  label="1-ci ödənişin tarixi"
                  defaultValue=""
                  fullWidth
                  name="wage.paymentPeriodDate"
                  onChange={(event) => {
                    setFieldValue("wage.paymentPeriodDate", event.target.value);
                  }}
                />
              )}
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel>Qeyri-iş günlərində işlədikdə</InputLabel>
                <Field
                  as={Select}
                  label="Qeyri-iş günlərində işlədikdə"
                  defaultValue=""
                  fullWidth
                  name="wage.freelanceWorkIncomeType"
                  onChange={(event) => {
                    setFieldValue(
                      "wage.freelanceWorkIncomeType",
                      event.target.value
                    );
                  }}
                >
                  {selectedValue?.freelanceWorkIncomeType &&
                    selectedValue?.freelanceWorkIncomeType.map((item, i) => (
                      <MenuItem key={`${item.value}${i}`} value={item.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
              <TextField
                type="number"
                label="Qeyri-iş günlərində işlədikdə əlavə gəlir"
                defaultValue=""
                fullWidth
                name="wage.freelanceWorkIncome"
                onChange={(event) => {
                  setFieldValue("wage.freelanceWorkIncome", event.target.value);
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </CustomAccordion>
      {openAddDialog && (
        <NewCommonDataDialog
          submitted={submitted}
          selectedType={selectedType}
          setSubmitted={setSubmitted}
          openAddDialog={openAddDialog}
          setOpenAddDialog={setOpenAddDialog}
        />
      )}
    </>
  );
};

export default Wage;
