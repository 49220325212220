import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  COLORS_STATUS,
  CONTRACTS_TYPE_DOC_MODELS_ENUMS,
} from "../../../constants";
import { getContract } from "../../../services/documentations/ContractServices";
import { formatDateTime } from "../../../utils/helperFunctions";
import DocumentDialog from "./DocumentDialog";
import {
  exclusionOrderGenerator,
  StructureOrderGenerator,
} from "./generatorFields/structureRequestOrder";
import { structureServiceGenerator } from "./generatorFields/structureServiceGenerator";
import {
  labourContractChangeGenerator,
  structureContractGenerator,
} from "./generatorFields/labourContractChangeGenerator";
import { Button } from "@mui/material";
import { approveLabourContract } from "../../../api/contractRequests/contractRequests";
import { enqueueSnackbar } from "notistack";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export default function ContractDialog({
  refreshPage,
  data,
  open,
  onClose,
  fetchHandler = undefined,
  id,
  disableSigning = false,
  navList = [],
}) {
  /*
      navList = [
        {
          value: string,
          label: "Sorghu",
        }
      ]
  */
  const [isLoading, setIsLoading] = useState(true);
  const [contractData, setContractData] = useState();
  const [fields, setFields] = useState([]);
  const [comments, setComments] = useState([]);
  const [status, setStatus] = useState();
  const [disableSign, setDisableSign] = useState(disableSigning);
  const [relatedDocs, setRelatedDocs] = useState([]);
  const [_index, setIndex] = useState(0);
  const [isGroupFields, setIsGroupFields] = useState(false);
  const [versionsList, setVersionsList] = useState([]);
  const [currVersion, setCurrVersion] = useState("");
  const { currentEmployee } = useSelector((state) => state.commonThings);
  useEffect(() => {
    setIsLoading(true);
    id ? setData(id) : setData(navList?.[0]?.value);
  }, []);

  useEffect(() => {
    if (navList && navList.length > 0) {
      setRelatedDocs(navList);
      const indexxx = navList.findIndex(
        (item) => item.value === contractData?._id
      );
      setIndex(indexxx);
    }
  }, [navList, contractData]);

  const appLabourContract = async () => {
    try {
      setIsLoading(true);
      const response = await approveLabourContract(id);
      if (response && response?.data) {
        setIsLoading(false);
        enqueueSnackbar("Əmək müqaviləsi təsdiqləndi", {
          variant: "success",
        });
        refreshPage();
        onClose();
      }
    } catch (error) {
      enqueueSnackbar("Xəta baş verdi", {
        variant: "error",
      });
      console.log("err", error);
    }
  };

  const checkReadyForLoading = () => {
    fields.length > 0 && setIsLoading(false);
  };

  const setData = async (id) => {
    await getData(id);
  };

  const getData = async (_id) => {
    try {
      setIsLoading(true);
      const { data } = fetchHandler
        ? await fetchHandler(_id, true)
        : await getContract(_id, true);
      if (id === _id) {
        generateNavList(data);
      }
      data && setContractData(data);
      data && generateData(data);
    } catch (error) {
      error.response.data.errors.map((item) => {
        let message = item.message;
        if (message === "You don't have enough privilege for view") {
          message = "Sizin bu hissəyə daxil olmaq üçün müvafiq icazəniz yoxdur";
        }
        return toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    } finally {
      setIsLoading(false);
    }
  };

  const generateNavList = (data) => {
    const list = [];

    if (navList && navList.length > 0) {
      setRelatedDocs(navList);
      return;
    }

    data?.relatedContracts.map((item, index) => {
      item.docId === id && setIndex(index);
      const obj = {
        label: normalizeDocName(item.contractType),
        value: item.docId,
      };
      // item.docId === data._id ? list.unshift(obj) :
      list.push(obj);
    });
    list.length > 1 && setRelatedDocs(list);
  };

  const normalizeDocName = (key) => {
    switch (key) {
      case "employment-application":
        return "Ərizə";
      case "labour-contract":
        return "Müqavilə";
      case "labour-order":
        return "Əmr";
      case "vacation-order":
        return "Məzuniyyət əmri";
      case "vacation-application":
        return "Məzuniyyət ərizəsi";
      case "labour-termination-order":
        return "Xitam əmri";
      default:
        return key;
    }
  };

  const generateData = (data) => {
    if (!data) return;
    // if (data.contractType.name === "structure-request-service") {
    //   setIsGroupFields(false);
    // } else {
    setIsGroupFields(true);
    // }
    generateFields(data);
    generateComments(data);
    generateStatus(data);
    generateVersions(data);
  };

  const generateVersions = (data) => {
    if (versionsList?.length > 0) return;
    const list = [];
    const versions = data.oldVersions;

    if (versions?.length === 0) {
      list.push({
        label: "1",
        value: id,
      });
    } else {
      versions?.map((item, index) => {
        list.push({
          label: `${index + 1}`,
          value: item.contract._id,
        });
      });

      list.push({
        label: `${versions?.length + 1}`,
        value: id,
      });
    }

    setCurrVersion(list[list.length - 1].value);

    setVersionsList(list);
  };
  const generateFields = (data) => {
    // if (data.contractType.name === "structure-request-service") {
    //   const arr = loopingFields(data.data);
    //   const subjectsField = findSubjectsFields(data.subjects);
    //   const generalDetails = findGeneralDetails(data);
    //   arr.unshift(subjectsField, ...generalDetails);
    //   setFields([...arr]);
    //   return;
    // }
    if (data.contractType.name === "structure-request-order") {
      const fields = StructureOrderGenerator(data);
      setFields(fields);
      return;
    }
    if (data.contractType.name === "structure-request-service") {
      const fields = structureServiceGenerator(data);
      setFields(fields);
      return;
    }
    if (data.contractType.name === "labourContractUpdate") {
      const fields = labourContractChangeGenerator(data);
      setFields(fields);
      return;
    }
    if (data.contractType.name === "exclusion-order") {
      const fields = exclusionOrderGenerator(data);
      setFields(fields);
      return;
    }
    setFields(data.data);
  };
  const generateComments = (data) => {
    const verifiers = data.verifiers;
    const _comments = [];
    const labelMsg = `Şərh: `;

    const status = (status) => {
      switch (status) {
        case "approved":
          return "təsdiq edirəm";
        case "rejected":
          return "imtina edirəm";
        case ("pending", "draft"):
          return "qərar gözlənilir";
        default:
          return "...";
      }
    };

    if (verifiers.length === 0) return;
    // eslint-disable-next-line array-callback-return
    verifiers.map((item) => {
      // eslint-disable-next-line array-callback-return
      if (item.status === "pending") return;

      const employeeName = `${item.employee.firstname} ${item.employee.lastname}`;
      const positionName = item.employee?.position?.name
        ? ` (${item.employee.position.name})`
        : "";
      const label = `${labelMsg}${employeeName}${positionName} - ${status(
        item.status
      )}`;

      _comments.push({
        label: label,
        value: item.description,
      });
    });
    setComments([..._comments]);
  };
  //   const generateHeader = (data) => {};
  const generateStatus = (data) => {
    // check verifier logged and status
    checkVerifierStatus(data);
    setStatus(data.status);
  };

  const checkVerifierStatus = (data) => {
    // signing buttons show only pending contract
    if (data.status !== "pending") {
      setDisableSign(true);
      return;
    }

    // logged user has on signers
    const founded = data.verifiers?.find(
      (item) => item.employee.id === currentEmployee._id
    );

    // if will not
    if (!founded || founded.status !== "pending") {
      setDisableSign(true);
      return;
    }

    // if (data.selfSign) {
    //   setDisableSign(true);
    //   return;
    // }

    setDisableSign(false);
  };

  // #region handlers of fields
  const loopingFields = (fields) => {
    const arr = [];

    const isDate = (value) => {
      return formatDateTime(value) !== "Invalid Date";
    };

    for (const key in fields) {
      const item = fields[key];
      let obj;

      // const groupArr = item.map((i) => {
      //   return {
      //     label: i.label,
      //     value: i.value,
      //     type: i.type,
      //   };
      // });

      // arr.push({
      //   [key]: groupArr,
      // });

      if (typeof item.value === "object") {
        obj = specialFieldTypeObject(item);
      } else if (isDate(item.value)) {
        obj = {
          label: item.label,
          value: item.value,
          type: "date",
        };
      } else {
        obj = {
          label: item.label,
          value: item.value,
          type: "string",
        };
      }

      arr.push(obj);
    }

    return arr;
  };
  const findSubjectsFields = (subjects) => {
    let subjectsName = "";

    subjects?.forEach((item, index) => {
      if (!item.subject || Object.keys(item.subject)?.length <= 1) return;

      const subject = item?.subject?.employee
        ? item.subject?.employee
        : item.subject;
      const withComma = index !== 0 ? ", " : "";

      subjectsName = `${subjectsName}${withComma}${subject.lastname} ${subject.firstname} ${subject.fathername}`;
    });

    const obj = {
      label: "Subyekt(lər)in adı, soyadı, ata adı",
      value: subjectsName,
      type: "string",
    };

    return obj;
  };
  const findGeneralDetails = (data) => {
    const arr = [];
    const { firstname, lastname, position } = data.creator
      ? data.creator
      : {
          firstname: "_",
          lastname: "_",
          position: "_",
        };
    const contractNo = validateValue(data?.data?.docNumber?.value?.toString());
    const creator = `${validateValue(firstname)} ${validateValue(
      lastname
    )} - ${validateValue(position?.name)}`;
    const department = validateValue(data.topPartOfStructures[0]?.source.name);
    const type = findDocType(data.contractType.type);
    const dateOfCreate = data.createdAt ? data.createdAt : "";

    arr.push(
      { label: "Sənəd No", value: contractNo, type: "string" },
      { label: "Sənəd sorğusunu yaradan", value: creator, type: "string" },
      { label: "Struktur departement", value: department, type: "string" },
      { label: "Sənədin növü", value: type, type: "string" },
      {
        label: "Sənədin/Sorğunun yaradılma tarixi",
        value: dateOfCreate,
        type: "date",
      }
    );

    return arr;
  };
  const specialFieldTypeObject = (obj) => {
    switch (obj.label) {
      case "Əmək şəraiti":
        return {
          label: obj.label,
          value: `(${obj.value.reason}), ${obj.value.value} ${obj.value.valueType} - ${obj.value.vacationDays} gün`,
        };
      default:
        return obj;
    }
  };
  // #endregion handlers of fields

  const validateValue = (value) => {
    return value && value.length > 0 ? value : "";
  };

  const onCloseHandler = () => {
    onClose();
    setContractData(null);
    setRelatedDocs([]);
  };
  const generateBgColor = () => {
    if (status) {
      switch (status) {
        case "draft":
        case "pending":
          return COLORS_STATUS.BLUE;
        case "approved":
          return COLORS_STATUS.GREEN;
        case "rejected":
          return COLORS_STATUS.RED;
        case "pendingUpdate":
          return COLORS_STATUS.BLUE;
        case "updated":
          return COLORS_STATUS.GREEN;
        default:
          return COLORS_STATUS.GREY;
      }
    }
  };
  const generateHeader = () => {
    const docType = findDocType(contractData?.contractType?.type);
    switch (status) {
      case "updated":
        return `Yenilənmiş ${docType}`;
      case "draft":
        return `Təsdiq gözləyən ${docType}`;
      case "pending":
        return `İmza gözləyən ${docType}`;
      case "approved":
        return `Qüvvədə olan ${docType}`;
      case "pendingUpdate":
        return `İmza gözləyən dəyişiklik edilmiş ${docType}`;
      case "rejected":
        return `İmtina edilmiş ${docType}`;
      default:
        return "Sorğu";
    }
  };

  const findDocType = (type) => {
    switch (type) {
      case CONTRACTS_TYPE_DOC_MODELS_ENUMS.AGREEMENT:
        return "müqavilə";
      case CONTRACTS_TYPE_DOC_MODELS_ENUMS.APPLICATION:
        return "ərizə";
      case CONTRACTS_TYPE_DOC_MODELS_ENUMS.ORDER:
        return "əmr";
      case CONTRACTS_TYPE_DOC_MODELS_ENUMS.SERVICE:
        return "xidməti yazı";
      case CONTRACTS_TYPE_DOC_MODELS_ENUMS.Explanation:
        return "izahat";
      default:
        return "";
    }
  };

  const docNumberHandler = () => {
    const docNo = contractData
      ? validateValue(
          contractData?.data?.contract_general_info?.map((item) => {
            if (item.key === "docNumber") return item.value;
          })
        )
      : validateValue(
          contractData?.data?.document_general_info?.map((item) => {
            if (item.key === "docNumber") {
              return item.value;
            }
          })
        );
    return docNo;
  };
  return (
    <>
      <DocumentDialog
        refreshPage={refreshPage}
        isGroup={isGroupFields}
        onClose={onCloseHandler}
        open={open}
        components={{
          footer: status === "draft" && (
            <div className="d-flex justify-content-center">
              {" "}
              <Button
                variant="contained"
                className="mb-3"
                onClick={appLabourContract}
              >
                Təsdiq et
              </Button>
            </div>
          ),
        }}
        fieldsData={fields}
        options={{
          bgColor: generateBgColor(),
          title: generateHeader(),
        }}
        commentsData={comments}
        signingProps={{
          acceptBtnTitle: "İmzala",
          rejectBtnTitle: "İmtina et",
          hide: status !== "pending" || status === "draft" || disableSign,
        }}
        id={contractData?._id}
        downloadBtnProps={{
          generateHref: (value) =>
            `${BASE_URL}/employee/contract/${value}/download`,
          value: contractData?._id,
        }}
        contractNo={contractData && docNumberHandler()}
        loading={isLoading}
        navbarData={relatedDocs}
        navbarProps={{
          currTabValue: async (id) => {
            setFields([]);
            setComments();
            setStatus();
            setDisableSign(true);
            await getData(id, getContract);
          },
          index: _index,
        }}
        typeDoc={contractData?.contractType?.name}
        versionData={versionsList}
        versionsProps={{
          versionHandler: async (id) => {
            setFields([]);
            setComments();
            setStatus();
            setDisableSign(true);
            setCurrVersion(id);
            await getData(id, getContract);
          },
          currVersionState: currVersion,
        }}
      />
    </>
  );
}
