import React, { useEffect, useState } from "react";
import CustomAccordion from "../../../../components/ui/Accordion/Accordion";
import {
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Field } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getGraphicSettings } from "../../../../api/graphicSettings";

const WorkVacationTime = ({
  values,
  weekDays,
  setFieldValue,
  selectedValue,
}) => {
  const [graphic, setGraphic] = useState([]);
  const [graphicName, setGraphicName] = useState("");
  const filterGraphic = [
    { column: "scheduleType", operator: "equalsTo", value: graphicName },
  ];

  const handleGetGraphic = async () => {
    try {
      const params = {
        limit: 10,
        offset: 0,
      };
      if (graphicName !== "") {
        params.filter = encodeURIComponent(JSON.stringify(filterGraphic));
      }

      const resp = await getGraphicSettings(params);
      setGraphic(resp?.data?.records);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetGraphic();
  }, [graphicName]);

  return (
    <>
      <CustomAccordion sx={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <h6>İş və istirahət vaxtı üzrə</h6>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Grid sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel>İş rejimi</InputLabel>
                <Field
                  as={Select}
                  label="İş rejimi"
                  defaultValue=""
                  fullWidth
                  name="workVacationTime.workingMode"
                  onChange={(event) => {
                    setFieldValue(
                      "workVacationTime.workingMode",
                      event.target.value
                    );
                    setGraphicName(event.target.value);
                  }}
                >
                  {selectedValue?.workingMode &&
                    selectedValue?.workingMode.map((item, i) => (
                      <MenuItem key={`${item.value}${i}`} value={item.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
            </Grid>
            {values?.workVacationTime?.workingMode !== "other" &&
            values?.workVacationTime?.workingMode !== "" ? (
              <FormControl fullWidth>
                <InputLabel>Həftəlik istirahət günləri</InputLabel>
                <Field
                  as={Select}
                  label="Həftəlik istirahət günləri"
                  multiple
                  fullWidth
                  name="workVacationTime.offDays"
                  value={values.workVacationTime.offDays}
                  onChange={(event) => {
                    setFieldValue(
                      "workVacationTime.offDays",
                      event.target.value
                    );
                  }}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (val) => weekDays.find((day) => day.key === val)?.name
                      )
                      .join(", ")
                  }
                >
                  {weekDays.map((week, index) => (
                    <MenuItem key={`${week.key}${index}`} value={week.key}>
                      <Checkbox
                        checked={
                          values.workVacationTime.offDays.indexOf(week.key) > -1
                        }
                      />
                      <ListItemText primary={week.name} />
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            ) : null}

            {values?.workVacationTime?.workingMode !== "" &&
              (values?.workVacationTime?.workingMode !== "other" ? (
                <FormControl fullWidth>
                  <InputLabel>Qrafiklər</InputLabel>
                  <Field
                    as={Select}
                    label="İş rejimi"
                    defaultValue=""
                    fullWidth
                    name="workVacationTime.workScheduleId"
                    onChange={(event) => {
                      setFieldValue(
                        "workVacationTime.workScheduleId",
                        event.target.value
                      );
                    }}
                  >
                    {graphic &&
                      graphic.map((item, i) => (
                        <MenuItem key={`${item._id}${i}`} value={item._id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                  </Field>
                </FormControl>
              ) : (
                <TextField
                  type="text"
                  label="Digər qrafik haqqında"
                  defaultValue=""
                  fullWidth
                  name="workVacationTime.workScheduleAsText"
                  onChange={(event) => {
                    setFieldValue(
                      "workVacationTime.workScheduleAsText",
                      event.target.value
                    );
                  }}
                />
              ))}
          </Grid>
        </AccordionDetails>
      </CustomAccordion>
    </>
  );
};

export default WorkVacationTime;
