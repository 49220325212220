import moment from "moment";
import Column from "../../../../models/table/Column";
import { OPERATORS_CONTAINING } from "../../../../constants";
import { GROUP_NAME } from "../../../../components/dialogs/documentDialog/dialogConstants";

export const empConstInitialValues = {
  labourPeriod: {
    contractPeriodType: "",
    probationPeriodType: "",
    probationPeriod: "",
    reasonOfTemporaryContract: "",
    contractStartDate: "",
    contractEndDate: "",
    jobStartDate: "",
    laborFunctions: [],
  },
  wage: {
    paymentOfWork: "",
    wage: "",
    bonusPeriod: "",
    givenBonus: "",
    partTimeWorkHour: "",
    overtime: "",
    workingCondition: {
      name: "",
      parent: "",
      reason: "",
      vacationDays: "",
      value: "",
      valueType: "",
    },
    selectedBank: "",
    conditionsOfSalaryPayment: "",
    paymentPeriod: "",
    paymentPeriodDate: "",
    freelanceWorkIncomeType: "",
    freelanceWorkIncome: "",
  },
  protection: {
    providedProtectiveEquipment: [],
    providedFood: [],
    instructionalPeriod: "",
  },
  workVacationTime: {
    workingMode: "",
    offDays: [],
    workScheduleId: "",
    workScheduleAsText: "",
  },
  vacation: {
    vacationDaysCount: "",
    forSeniorityLevel: "",
    forParental: "",
    forCollectiveAgreement: "",
    socialHouseholdBenefit: "",
    aboutEventOrPayer: "",
  },
  physicalEducationSport: {
    physicalEducationAndSportsConditions: "",
    otherAdditionalConditions: "",
  },
  socialProtection: {
    monthlyInsuranceFee: "",
    extraSumInsured: "",
  },
  regulationPropertyRelation: {
    shareOwnershipOfEnterprise: "",
    dividendAmount: "",
    personalPropertiesUsedByEmployee: [],
  },
};

export const weekDays = [
  { key: "monday", name: "Bazar ertəsi" },
  { key: "tuesday", name: "Çərşənbə axşamı" },
  { key: "wednesday", name: "Çərşənbə" },
  { key: "thursday", name: "Cümə axşamı" },
  { key: "friday", name: "Cümə" },
  { key: "saturday", name: "Şənbə" },
  { key: "sunday", name: "Bazar" },
];

export function empConstModifeValue(values, selectedCont) {
  return {
    staffs: Array.isArray(selectedCont) ? selectedCont : [selectedCont],
    updates: [
      {
        section: "labourPeriod",
        data: {
          contractPeriodType: values?.labourPeriod?.contractPeriodType
            ? values?.labourPeriod?.contractPeriodType
            : "",
          probationPeriodType: values?.labourPeriod?.probationPeriodType
            ? values?.labourPeriod?.probationPeriodType
            : "",
          probationPeriod: !isNaN(
            parseInt(values?.labourPeriod?.probationPeriod)
          )
            ? parseInt(values?.labourPeriod?.probationPeriod)
            : "",
          reasonOfTemporaryContract: !isNaN(
            values?.labourPeriod?.reasonOfTemporaryContract
          )
            ? values?.labourPeriod?.reasonOfTemporaryContract
            : "",
          contractStartDate: values?.labourPeriod?.contractStartDate
            ? moment(values?.labourPeriod?.contractStartDate).format(
                "MM/DD/yyyy"
              )
            : "",
          contractEndDate: values?.labourPeriod?.contractEndDate
            ? moment(values?.labourPeriod?.contractEndDate).format("MM/DD/yyyy")
            : "",
          jobStartDate: values?.labourPeriod?.jobStartDate
            ? moment(values?.labourPeriod?.jobStartDate).format("MM/DD/yyyy")
            : "",
          laborFunctions:
            values?.labourPeriod?.laborFunctions.length > 0
              ? values?.labourPeriod?.laborFunctions
              : "",
        },
      },
      {
        section: "wage",
        data: {
          paymentOfWork: values?.wage?.paymentOfWork
            ? values?.wage?.paymentOfWork
            : "",
          wage: !isNaN(parseInt(values?.wage?.wage))
            ? parseInt(values?.wage?.wage)
            : "",
          bonusPeriod: values?.wage?.bonusPeriod
            ? values?.wage?.bonusPeriod
            : "",
          givenBonus: !isNaN(parseInt(values?.wage?.givenBonus))
            ? parseInt(values?.wage?.givenBonus)
            : "",
          partTimeWorkHour: !isNaN(parseInt(values?.wage?.partTimeWorkHour))
            ? parseInt(values?.wage?.partTimeWorkHour)
            : "",
          overtime: !isNaN(parseInt(values?.wage?.overtime))
            ? parseInt(values?.wage?.overtime)
            : "",
          workingCondition: values?.wage?.workingCondition
            ? values?.wage?.workingCondition
            : "",
          selectedBank: values?.wage?.selectedBank,
          conditionsOfSalaryPayment: values?.wage?.conditionsOfSalaryPayment
            ? values?.wage?.conditionsOfSalaryPayment
            : "",
          paymentPeriod: !isNaN(parseInt(values?.wage?.paymentPeriod))
            ? parseInt(values?.wage?.paymentPeriod)
            : "",
          paymentPeriodDate: values?.wage?.paymentPeriodDate
            ? values?.wage?.paymentPeriodDate
            : "",
          freelanceWorkIncomeType: values?.wage?.freelanceWorkIncomeType
            ? values?.wage?.freelanceWorkIncomeType
            : "",
          freelanceWorkIncome: values?.wage?.freelanceWorkIncome
            ? values?.wage?.freelanceWorkIncome
            : "",
        },
      },
      {
        section: "protection",
        data: {
          providedProtectiveEquipment: Array.isArray(
            values?.protection?.providedProtectiveEquipment
          )
            ? values?.protection?.providedProtectiveEquipment.length > 0
              ? [values?.protection?.providedProtectiveEquipment]
              : ""
            : values?.protection?.providedProtectiveEquipment
            ? [values?.protection?.providedProtectiveEquipment]
            : "",
          providedFood: Array.isArray(values?.protection?.providedFood)
            ? values?.protection?.providedFood.length > 0
              ? [values?.protection?.providedFood]
              : ""
            : values?.protection?.providedFood
            ? [values?.protection?.providedFood]
            : "",

          instructionalPeriod: values?.protection?.instructionalPeriod,
        },
      },
      {
        section: "workVacationTime",
        data: {
          offDays:
            values?.workVacationTime?.offDays.length > 0
              ? values?.workVacationTime?.offDays
              : "",
          workScheduleId: values?.workVacationTime?.workScheduleId
            ? values?.workVacationTime?.workScheduleId
            : "",
          workScheduleAsText: values?.workVacationTime?.workScheduleAsText
            ? values?.workVacationTime?.workScheduleAsText
            : "",
        },
      },
      {
        section: "vacation",
        data: {
          vacationDaysCount: values?.vacation?.vacationDaysCount
            ? values?.vacation?.vacationDaysCount
            : "",
          forSeniorityLevel: !isNaN(values?.vacation?.forSeniorityLevel)
            ? values?.vacation?.forSeniorityLevel
            : "",
          aboutEventOrPayer: values?.vacation?.aboutEventOrPayer
            ? values?.vacation?.aboutEventOrPayer
            : "",
          forParental: !isNaN(values?.vacation?.forParental)
            ? values?.vacation?.forParental
            : "",
          forCollectiveAgreement: !isNaN(
            parseInt(values?.vacation?.forCollectiveAgreement)
          )
            ? parseInt(values?.vacation?.forCollectiveAgreement)
            : "",
          socialHouseholdBenefit: !isNaN(
            parseInt(values?.vacation?.socialHouseholdBenefit)
          )
            ? parseInt(values?.vacation?.socialHouseholdBenefit)
            : "",
        },
      },
      {
        section: "physicalEducationSport",
        data: {
          physicalEducationAndSportsConditions: values?.physicalEducationSport
            ?.physicalEducationAndSportsConditions
            ? values?.physicalEducationSport
                ?.physicalEducationAndSportsConditions
            : "",
          otherAdditionalConditions: values?.physicalEducationSport
            ?.otherAdditionalConditions
            ? values?.physicalEducationSport?.otherAdditionalConditions
            : "",
        },
      },
      {
        section: "socialProtection",
        data: {
          monthlyInsuranceFee: !isNaN(
            parseInt(values?.socialProtection?.monthlyInsuranceFee)
          )
            ? parseInt(values?.socialProtection?.monthlyInsuranceFee)
            : "",
          extraSumInsured: !isNaN(
            parseInt(values?.socialProtection?.extraSumInsured)
          )
            ? parseInt(values?.socialProtection?.extraSumInsured)
            : "",
        },
      },
      {
        section: "regulationPropertyRelation",
        data: {
          shareOwnershipOfEnterprise: !isNaN(
            parseInt(
              values?.regulationPropertyRelation?.shareOwnershipOfEnterprise
            )
          )
            ? parseInt(
                values?.regulationPropertyRelation?.shareOwnershipOfEnterprise
              )
            : "",
          dividendAmount: !isNaN(
            parseInt(values?.regulationPropertyRelation?.dividendAmount)
          )
            ? parseInt(values?.regulationPropertyRelation?.dividendAmount)
            : "",
          personalPropertiesUsedByEmployee:
            values?.regulationPropertyRelation?.personalPropertiesUsedByEmployee
              .length > 0
              ? values?.regulationPropertyRelation
                  ?.personalPropertiesUsedByEmployee
              : "",
        },
      },
    ].filter((update) => {
      const { data } = update;

      return Object.keys(data).some((key) => {
        const value = data[key];
        const allEmptyStrings =
          typeof value === "object" &&
          Object.values(value).every((v) => v === "");

        return (
          value !== "" &&
          !(Array.isArray(value) && value.length === 0) &&
          !(
            typeof value === "object" &&
            (Object.keys(value).length === 0 || allEmptyStrings)
          )
        );
      });
    }),
  };
}

export const empConstColumns = (
  getDescendantProp,
  statusColumnGenerator,
  operatorColumnGenerator
) => {
  return [
    new Column({
      headerName: "Ə.M. No.",
      path: "data.contract.docNumber",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      headerName: "Dəyişiklik sorğusu tarixi",
      path: "data.contract.contractUpdateDate",
      filterType: OPERATORS_CONTAINING.DATE,
    }),
    new Column({
      headerName: "Dəyişikliklər",
      path: "data.updates",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row, column) => {
        const arr = getDescendantProp(row, column.path);
        const changes = arr?.map((item) => GROUP_NAME[item.section]);

        return changes?.join(", ");
      },
    }),
    new Column({
      headerName: "A.S.A.",
      path: "data.contract.employeeFullName",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      headerName: "Qüvvəyə minmə tarixi",
      path: "data.effectiveDate",
      filterType: OPERATORS_CONTAINING.DATE,
    }),
    new Column({
      headerName: "Status",
      path: "status",
      renderCell: statusColumnGenerator,
      filterType: OPERATORS_CONTAINING.OPTION,
    }),
    new Column({
      headerName: "Operator",
      isStaticColumn: true,
      path: "#",
      renderCell: operatorColumnGenerator,
    }),
  ];
};
