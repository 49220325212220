/* eslint-disable prettier/prettier */
import {
  Grid,
  Button,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";

import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import { spacing } from "@mui/system";

import React, { useEffect, useState } from "react";
import Header from "../../components/layouts/header/Header";
import { DatePicker } from "@mui/x-date-pickers";
import { getAllEmployees } from "../../api/api";
import styled from "styled-components";

import {
  addNewMedicalOrganization,
  createNewBulletinData,
  fileToBase64,
  getMedicalOrganizationsList,
} from "./services";

const CloudUpload = styled(MuiCloudUpload)(spacing);

export default function BulletinCreate() {
  const data = new URLSearchParams(document.location.search);
  const [unknownReason, setUnknownReason] = useState(data.get("unknownreason"));
  const [employee, setEmployee] = useState(data.get("employee"));
  const [startDate, setStartDate] = useState(data.get("startDate"));

  // employees
  const [employees, setEmployees] = useState({});
  // butun tibbi muessiseler
  const [medicalOrgs, setMedicalOrgs] = useState([]);
  // elave olunan tibbi muessise
  const [medicalOrgName, setMedicalOrgName] = useState("");

  // dialogs

  const [medicalOrgAdd, setMedicalOrgAdd] = useState(false);

  // create button disable state
  const [create_btn_disable, set_create_btn_disable] = useState(false);
  // bulletin yarat melumatlari
  const [leave_state, set_leave_state] = useState({
    medicalOrganization: "",
    employee: employee,
    startDate: startDate,
    endDate: "",
    submissionDate: "",
    returnDate: "",
    issuancePdf: [],
    reason: "",
    documentNumber: "",
    unexplainedAbsenceId: unknownReason,
  });
  // bulletin melumat yenileme
  const [updateDatas, setUpdateDatas] = useState({
    medicalOrganization: "",
    startDate: "",
    endDate: "",
    submissionDate: "",
    returnDate: "",
    documentNumber: "",
  });
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    set_create_btn_disable(
      leave_state?.submissionDate === "" ||
        leave_state?.medicalOrganization === "" ||
        leave_state?.returnDate === "" ||
        leave_state?.startDate === "" ||
        leave_state?.endDate === "" ||
        leave_state?.documentNumber === "" ||
        leave_state?.issuancePdf.length === 0 ||
        leave_state?.reason === "" ||
        leave_state?.employee === ""
    );
  }, [leave_state]);

  useEffect(() => {
    getEmployees();
    getMedicalOrganizations();
  }, []);

  //api sorgulari

  // emekdaslarin siyahisi sorgusu
  const getEmployees = async () => {
    try {
      const response = await getAllEmployees();
      if (response?.data?.employees) {
        let emp = response?.data?.employees
          ?.filter((e) => e?.status !== "inactive")
          .map((e) => ({
            firstname: e?.firstname,
            lastname: e?.lastname,
            fathername: e?.fathername,
            id: e?._id,
          }));
        setEmployee(emp.find((item) => item.id === employee));
        setEmployees(emp);
      }
    } catch (error) {
      console.log("general statistics", error);
    }
  };

  // sehiyye orqanlari sorgusu
  const getMedicalOrganizations = async () => {
    try {
      const response = await getMedicalOrganizationsList();
      if (response?.data) {
        setMedicalOrgs(response.data);
      }
    } catch (error) {
      console.log("medicalorg", error);
    }
  };
  // yeni sehiyye orqani yaradilmasi sorgusu
  const createNewMedicalOrg = async (data) => {
    await addNewMedicalOrganization([
      {
        key: "medicalOrganizationList",
        values: [data],
      },
    ]);
    await getMedicalOrganizations();
  };
  // yeni bulleten yaradilma sorgusu
  const createNewBulletin = async (data) => {
    if (data.issuancePdf) {
      const file = data.issuancePdf;
      const base64Data = await fileToBase64(file);

      data = { ...data, issuancePdf: base64Data };
    }
    await createNewBulletinData(data);
    set_leave_state({
      medicalOrganization: "",
      employee: employee,
      startDate: startDate,
      endDate: "",
      submissionDate: "",
      returnDate: "",
      issuancePdf: [],
      reason: "",
      documentNumber: "",
      unexplainedAbsenceId: unknownReason,
    });
  };

  // sehife basliqlari

  const headerData = {
    title: "Əmək qabiliyyətinin müvəqqəti itirilməsi",
    variant: "h1",
  };

  return (
    <React.Fragment>
      <Grid sx={{ width: "100%", textAlign: "center", marginBottom: "50px" }}>
        <Header data={headerData} />
      </Grid>
      <Box>
        <div className="d-flex">
          {/* emekdaslar */}
          <div className="w-25 ">
            <InputLabel sx={{ marginBottom: "8px", color: "white" }}>
              Əməkdaşlar
            </InputLabel>

            <Autocomplete
              sx={{ width: "100%" }}
              options={employees}
              renderInput={(params) => (
                <TextField {...params} label="Əməkdaşı seçin" />
              )}
              disabled={employee}
              getOptionLabel={(option) =>
                `${option.firstname} ${option?.lastname} ${option?.fathername}`
              }
              inputValue={
                employee
                  ? `${employee.firstname} ${employee.lastname} ${employee.fathername}`
                  : searchValue
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onInputChange={(event, newInputValue) => {
                setSearchValue(newInputValue);
              }}
              onChange={(e, newInputValue) => {
                set_leave_state({
                  ...leave_state,
                  employee: newInputValue?.id ? newInputValue.id : "",
                });
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {`${option.firstname} ${option.lastname} ${option.fathername}`}
                  </li>
                );
              }}
            />
          </div>
          {/* sehiyye ocaqlari */}
          <div className="w-25 ms-2 ">
            <InputLabel sx={{ color: "white" }}>Səhiyyə Ocaqları</InputLabel>
            <div className="mt-2">
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={leave_state?.medicalOrganization}
                  onChange={(e) =>
                    set_leave_state({
                      ...leave_state,
                      medicalOrganization: e.target.value,
                    })
                  }
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Səhiyyə ocağını seçin
                  </MenuItem>
                  {medicalOrgs?.length &&
                    medicalOrgs?.map((e, i) => (
                      <MenuItem key={i} value={e}>
                        {e}
                      </MenuItem>
                    ))}
                  <Button
                    sx={{ marginLeft: "10px" }}
                    variant="contained"
                    color="primary"
                    onClick={() => setMedicalOrgAdd(true)}
                  >
                    Əlavə et
                  </Button>
                </Select>
              </FormControl>
            </div>
          </div>
          {/* sened nomresi */}
          <div className="w-25 ms-2 ">
            <InputLabel sx={{ color: "white" }}>Sənəd nömrəsi</InputLabel>
            <div className="mt-2">
              <FormControl fullWidth>
                <TextField
                  value={leave_state?.documentNumber}
                  onChange={(e) =>
                    set_leave_state({
                      ...leave_state,
                      documentNumber: e.target.value,
                    })
                  }
                  displayEmpty
                />
              </FormControl>
            </div>
          </div>
          {/* file yukle */}
          <div
            className="w-25 ms-2 "
            style={{
              display: "flex",
            }}
          >
            <InputLabel sx={{ color: "white" }}>Fayl yüklə</InputLabel>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <span className="upload" style={classes.mr_6}>
                {leave_state.issuancePdf.length !== 0 ? (
                  <span>{leave_state.issuancePdf.name}</span>
                ) : (
                  <></>
                )}
                <input
                  type="file"
                  id="raised-button-file"
                  name="templateFile"
                  accept=".pdf, .doc, .docx"
                  style={classes.d_none}
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      const file = e.target.files[0];
                      set_leave_state({
                        ...leave_state,
                        issuancePdf: file,
                      });
                    }
                  }}
                />
                <label htmlFor="raised-button-file">
                  <Button variant="outlined" color="primary" component="span">
                    <CloudUpload mr={2} /> Fayl yüklə
                  </Button>
                </label>
              </span>
            </div>
          </div>
        </div>
        {/* sebeb */}
        <div className="d-flex mt-3">
          <div className="w-100 ">
            <div>Səbəbi</div>
            <div className="mt-2">
              <TextField
                onChange={(e) =>
                  set_leave_state({
                    ...leave_state,
                    reason: e.target.value,
                  })
                }
                value={leave_state?.reason}
                fullWidth
                placeholder="Səbəbi"
              />
            </div>
          </div>
        </div>
        {/* tarixler */}
        <div className="d-flex mt-3">
          <div className="w-25 ">
            <div>Təqdim etmə tarixi</div>
            <div className="mt-2">
              <DatePicker
                inputFormat="dd/MM/yyyy"
                value={leave_state?.submissionDate || null}
                // maxDate={new Date()}
                // minDate={new Date(new Date().getTime() - 86400000 * 30)}
                onChange={(date) =>
                  set_leave_state({ ...leave_state, submissionDate: date })
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </div>
          </div>
          <div className="w-25 ms-2">
            <div>Başlama tarixi</div>
            <div className="mt-2">
              <DatePicker
                disabled={startDate}
                inputFormat="dd/MM/yyyy"
                value={leave_state?.startDate || null}
                // maxDate={new Date()}
                // minDate={new Date(new Date().getTime() - 86400000 * 30)}
                onChange={(date) => {
                  set_leave_state({
                    ...leave_state,
                    startDate: date,
                  });
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </div>
          </div>
          <div className="w-25 ms-2">
            <div>Bitmə tarixi</div>
            <div className="mt-2">
              <DatePicker
                inputFormat="dd/MM/yyyy"
                value={leave_state?.endDate || null}
                // maxDate={new Date()}
                // minDate={new Date(new Date().getTime() - 86400000 * 30)}
                onChange={(date) =>
                  set_leave_state({ ...leave_state, endDate: date })
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </div>
          </div>
          <div className="w-25 ms-2">
            <div>İşə çıxma tarixi</div>
            <div className="mt-2">
              <DatePicker
                inputFormat="dd/MM/yyyy"
                value={leave_state?.returnDate || null}
                // maxDate={new Date()}
                // minDate={new Date(new Date().getTime() - 86400000 * 30)}
                onChange={(date) =>
                  set_leave_state({ ...leave_state, returnDate: date })
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </div>
          </div>
        </div>
        {/* yarat button */}
        <div className="w-25 mt-3">
          <Button
            onClick={() => {
              createNewBulletin(leave_state);
            }}
            variant="contained"
            disabled={create_btn_disable}
          >
            Yarat
          </Button>
        </div>
      </Box>
      {/* add medical organization dialog */}
      <Dialog
        open={medicalOrgAdd}
        onClose={() => setMedicalOrgAdd(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Yeni tibb müəssisəsi əlavə edin
        </DialogTitle>
        <div style={{ padding: "10px 20px" }}>
          <TextField
            onChange={(e) => setMedicalOrgName(e.target.value)}
            value={medicalOrgName}
            fullWidth
            placeholder="Tibb müəssisəsinin adını daxil edin"
          />
        </div>
        <DialogActions>
          <Button
            onClick={() => {
              createNewMedicalOrg(medicalOrgName);
              setMedicalOrgName("");
              set_leave_state({
                ...leave_state,
                medicalOrganization: "",
              });

              setMedicalOrgAdd(false);
            }}
          >
            Əlavə et
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

// CSS
const classes = {
  flex: {
    display: "flex",
    alignItems: "center",
  },
  cardContent: {
    padding: "16px",
    justifyContent: "space-between",
  },
  mb_3: {
    marginBottom: "8px",
  },
  mr_4: {
    marginRight: "4px",
  },
  mr_6: {
    marginRight: "6px",
  },
  mr_15: {
    marginRight: "15px",
  },
  d_none: {
    display: "none",
  },
  border_radius_8: {
    borderRadius: "8px",
  },
  select_styles: {
    position: "absolute",
    maxWidth: "20%",
    color: "#333",
  },
};
