/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
// Components
import StructureSelectCard from "../structureSelectCard";
import BaseLevelAccordion from "./BaseLevelAccordion";
// Material UI
import {
  Add as AddIcon,
  Clear,
  Edit,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
// SVG
import MonitorSVG from "../../../vendor/monitor.svg";
import UserWhiteSVG from "../../../vendor/white_users.svg";
// services
import * as structureServices from "../../../services/structure/StructureServices";
// Utilities
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { docModelTypes, structureNamingsEnum } from "../../../constants";
import { setTriggingRefresh } from "../../../redux/features/slices/commonThings.slice";
import { setStructureDataById } from "../../../redux/features/slices/structure.slice";
import * as companyPartServices from "../../../services/companyPart/CompanyPartServices";
import { findPositionById } from "../../../utils/helperFunctions";
import CheckboxInput from "../../layouts/checkbox/Checkbox";

const AccordionItem = ({
  classes,
  data,
  last_bunch,
  isClickChangeApproveRequest,
  createSubStructure,
  createPosition,
  handleSaveCompanyParts,
  handleViewPositions,
  parentItem,
  index,
  handleChangeStructureCheckbox,
  expanded,
  structure,
  checkedItems
}) => {
  console.log(checkedItems)
  let { id } = useParams();
  const dispatch = useDispatch();
  const common = useSelector((state) => state.commonThings);

  const [expanded_, setExpanded] = useState(expanded);
  const [isEdit, setIsEdit] = useState(false);
  const loadOptionsForCompanyParts = async (_search, loadedOptions, item) => {
    const _start = loadedOptions.length;

    const { parts, total } = await companyPartServices.getCompanyStructures({
      limit: 10,
      skip: _start,
      name: _search,
      type: item?.expectedType?._id,
      notUsed: true,
      parentRel: parentItem?.child?._id,
    });
    return {
      options: parts.map((item) => ({
        value: item._id,
        // label: item.name + " - " + generateCapatilazeWord(item?.type?.name),
        label: item.name,
      })),
      hasMore: _start < total - 1,
    };
  };

  useEffect(() => {
    setExpanded(expanded);
  }, [expanded]);
  const nestedAccordion = (data?.children || [])
    .filter(
      (item) => item.child.expectedType.name !== structureNamingsEnum.POSITION
    )
    .map((item, index) => {
      return item?.child?.type && item?.child?.type?.name ? (
        <AccordionItem
          key={data._id}
          data={item}
          last_bunch={item}
          classes={classes}
          createPosition={createPosition}
          createSubStructure={createSubStructure}
          loadOptionsForCompanyParts={loadOptionsForCompanyParts}
          handleSaveCompanyParts={handleSaveCompanyParts}
          handleViewPositions={handleViewPositions}
          parentItem={data}
          handleChangeStructureCheckbox={handleChangeStructureCheckbox}
          type="child"
          structure={structure}
          checkedItems={checkedItems}
          isClickChangeApproveRequest={isClickChangeApproveRequest}
        />
      ) : (
        <StructureSelectCard
          classes={classes}
          item={item}
          loadOptionsForCompanyParts={loadOptionsForCompanyParts}
          handleSaveCompanyParts={handleSaveCompanyParts}
          childData={data?.child}
          isContentLeft={true}
        />
      );
    });

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  async function deleteChilRelation(relId) {
    try {
      let response = await structureServices.deleteChildRel(relId);
      if (response) {
        const structure = await structureServices.getStructureById(id);
        dispatch(setStructureDataById(structure));
        enqueueSnackbar(
          "Struktur hissə silinmə üçün seçildi! Zəhmət olmasa dəyişikliyi bitirin",
          { variant: "success" }
        );
      }
    } catch (error) {
      enqueueSnackbar("Xəta baş verdi", { variant: "error" });
    }
  }

  async function deleteInnerChild(childId) {
    try {
      let response = await structureServices.deleteChild(childId, id);
      if (response) {
        const structure = await structureServices.getStructureById(id);
        dispatch(setStructureDataById(structure));
        enqueueSnackbar(
          "Struktur hissə silinmə üçün seçildi! Zəhmət olmasa dəyişikliyi bitirin",
          { variant: "success" }
        );
      }
    } catch (error) {
      if (error.message === "Request already exist") {
        enqueueSnackbar("Hazırda dəyişiklik sorğusu mövcuddur", {
          variant: "error",
        });
      } else enqueueSnackbar("Xəta baş verdi", { variant: "error" });
    }
  }

  const handleOnChangeStructure = async (partId) => {
    try {
      const resp = await structureServices.updateStructureChild({
        structureId: structure._id,
        payload: { relId: data?.child?._id, partId: partId },
      });
      resp?.data && setIsEdit(false);
      data && dispatch(setTriggingRefresh(!common.refreshTrigger));
    } catch (error) {
      console.log("err", error);
      swal("Xəta baş verdi", { title: "Xəta" });
    }
  };

  const markedForDeletion_Style = {
    textDecoration: "line-through",
    textDecorationColor: "red",
    textDecorationThickness: "4px",
  };

  const EditButton = (
    <>
      <IconButton onClick={() => setIsEdit(true)}>
        <Edit sx={{ width: "24px", height: "24px" }} />
      </IconButton>
    </>
  );

  return (
    <Accordion
      expanded={expanded_}
      onChange={() =>
        setExpanded(
          data?.children?.length > 0 &&
            data?.children?.some(
              (d) => d?.child?.expectedType?.name !== "position"
            )
            ? !expanded_
            : null
        )
      }
    // onChange={
    //   parentItem?.children?.filter(
    //     (data) =>
    //       data?.child?.expectedType?.name !==
    //       structureNamingsEnum.POSITION
    //   )?.length > 0
    //     ? handleChange(index)
    //     : () => { }
    // }
    //   className={classes.accordion}
    >
      <AccordionSummary
        expandIcon={
          data?.children.filter(
            (item) =>
              item?.child?.expectedType?.name !== structureNamingsEnum.POSITION
          )?.length > 0 ? (
            <ExpandMoreIcon />
          ) : (
            <div style={{ width: "22px", height: "20px" }}> </div>
          )
        }
        style={{
          backgroundColor: data?.child?.type?.level?.style?.backgroundColor,
          border: `${data?.child?.type?.level?.style?.border?.width}px solid ${data?.child?.type?.level?.style?.border?.color}`,
        }}
        className={`${parentItem?.child?.docModel === docModelTypes.MANAGEMENT
          // ? classes.accordion_baseBg
          // : classes.accordion_inner_bg
          } ${classes.accordion_summary}`}
      >
        {parentItem?.child?.docModel === docModelTypes.COMPANYPART ? (
          <div className="d-flex justify-content-between align-items-center w-100 position-relative">
            <div className="w-25">
              {isEdit ? (
                <StructureSelectCard
                  classes={classes}
                  item={data}
                  isExistLeft={true}
                  loadOptionsForCompanyParts={(_search, loadedOptions) =>
                    loadOptionsForCompanyParts(
                      _search,
                      loadedOptions,
                      parentItem?.child,
                      true
                    )
                  }
                  handleSaveCompanyParts={(id) => handleOnChangeStructure(id)}
                  // childData={item?.child}
                  isContentLeft={true}
                  // structure_id={structureById?._id}
                  isEditMode={true}
                  onCancel={() => setIsEdit(false)}
                  bgColor={data?.child?.type?.level?.style?.backgroundColor}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    style={
                      data?.child?.markedForDeletion
                        ? markedForDeletion_Style
                        : null
                    }
                  >
                    {data?.child?.name}{" "}
                  </Typography>

                  {!isEdit && !data?.child?.markedForDeletion &&
                    (data?.child?.editable || !structure?.recruiterStaff) && EditButton}
                </div>
              )}

              {isClickChangeApproveRequest ? (
                <span className="d-inline-block mt-3 mb-3">
                  <CheckboxInput
                    labelContent={"Dəyişiləcək"}
                    id={data?.child?._id}
                    name={data?.child?.name + " " + data?.child?.type?.name}
                    onChange={handleChangeStructureCheckbox}
                    checked={checkedItems?.some(
                      (checkedItem) => checkedItem?.id === data?.child?._id
                    )}
                  />
                </span>
              ) : (
                <div style={{ color: "#9ce99c", fontSize: "16px" }}>
                  {data?.child?.requestedUser && (
                    <>
                      <b>{data?.child?.requestedUser}</b> hal hazırda dəyişiklik
                      edir
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="w-25" style={{ borderLeft: "2px solid #fff" }}>
              <div className={classes.card_wrap}>
                <div className={classes.card_wrap_icon}>
                  <span className={classes.mr_35}>
                    {data?.child?.details?.positionCount || 0}
                  </span>
                  <span>
                    <img src={MonitorSVG} alt="Monitor" />
                  </span>
                </div>
                <div className={classes.fs_18}>
                  <span>Vəzifə sayı</span>
                </div>
              </div>
            </div>
            <div
              className="w-50 d-flex justify-content-between align-items-center"
              style={{ borderLeft: "2px solid #fff" }}
            >
              <div className={classes.card_wrap}>
                <div className={classes.card_wrap_icon}>
                  <span className={classes.mr_35}>
                    {data?.child?.details?.staffCount || 0}
                  </span>
                  <span>
                    <img src={UserWhiteSVG} alt="Users" />
                  </span>
                </div>
                <div className={classes.fs_18}>
                  <span>Ştat sayı</span>
                </div>
              </div>
              <div className={`action_btns ${classes.pr_15}`}>
                {!data?.child?.markedForDeletion &&
                  (data?.child?.editable || !structure?.recruiterStaff) ? (
                  <>
                    <div className="mb-3">
                      <Button
                        variant="contained"
                        className={classes.sub_customize_buttons}
                        onClick={(e) => createSubStructure(e, data?.child?._id)}
                      >
                        <AddIcon />
                        Sub-struktur Əlavə Et
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        className={classes.sub_customize_buttons}
                        onClick={(e) => createPosition(e, data?.child?._id)}
                      >
                        <AddIcon />
                        Vəzifə Əlavə Et
                      </Button>
                    </div>
                  </>
                ) : null}
                {findPositionById(data?.children)?.length > 0 ? (
                  <div className="mt-3">
                    <Button
                      variant="contained"
                      className={classes.sub_customize_buttons}
                      onClick={(e) => {
                        handleViewPositions(
                          e,
                          findPositionById(data?.children),
                          data
                        );
                      }}
                    >
                      <VisibilityIcon className="mx-1" />
                      Ştat cədvəlinə bax
                    </Button>
                  </div>
                ) : null}
                {!data?.children?.length && data?.child?.editable && (
                  <Tooltip title="Struktur hissəni sil">
                    <Clear
                      style={{ position: "absolute", right: "-20px", top: 0 }}
                      onClick={() => {
                        !structure?.recruiterStaff || !data?.child?.isAccepted
                          ? deleteInnerChild(data?.child?._id)
                          : deleteChilRelation(data?.child?._id);
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        ) : (
          <BaseLevelAccordion
            classes={classes}
            item={data}
            createSubStructure={createSubStructure}
            createPosition={createPosition}
            handleViewPositions={handleViewPositions}
          />
        )}
      </AccordionSummary>
      <AccordionDetails className="accordion_details">
        {nestedAccordion}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionItem;
