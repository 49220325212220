import { RemoveRedEye } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../components/layouts/header/Header";
import OperatorGenerator from "../../components/table/OperatorGenerator";
import StatusGenerator from "../../components/table/StatusGenerator";
import Table from "../../components/table/Table";
import ButtonDropdown from "../../components/ui/ButtonDropdown/ButtonDropdown";
import Operator from "../../models/table/Operator";
import {
  downloadContractChangesCSV,
  getContractUpdates,
} from "../../services/labourContractUpdate/labourContractUpdateService";
import { getDescendantProp } from "../../utils/helperFunctions";
import StatisticaContChanges from "./parts/StatisticaContChanges";
import ContChangesModal from "./parts/modal/ContChangesModal";
import { downloadFile } from "../../utils/downloadCSV";
import EmpContsDialog from "./dialog/EmpContsDialog";
import EmpConstsGeneralDialog from "./dialog/EmpConstsGeneralDialog";
import { empConstColumns } from "./dialog/empConstMock/empConstMock";

const DropdownContainer = styled.div`
  width: 100%;
`;

const Element = styled.div`
  width: 100%;
  color: #fff;
  text-align: center;
  padding: 10px;
  height: auto;
  border-radius: 5px;
  transition: 0.3s all ease;
  font-weight: bold;

  &:hover {
    background-color: #fff;
    color: #000;
    cursor: pointer;
  }
`;

const EmpContsChanges = () => {
  //table
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState();

  // modals
  //  | create
  const [individualModal, setIndividualModal] = useState(false);
  const [generalModal, setGeneralModal] = useState(false);
  //  | view
  const [selectedId, setSelectedId] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    paginationHandler();
  }, [filters, limit, offset]);

  const paginationHandler = async () => {
    if (!filters.length > 0) {
      await fetchData();
      return;
    } else {
      const encodedFilter = encodeURIComponent(JSON.stringify(filters));
      await fetchData(encodedFilter);
    }
  };

  //fetch
  const fetchData = async (filters = undefined) => {
    try {
      setLoading(true);
      const payload = {
        offset: offset,
        limit: limit,
        filter: filters,
      };
      const { data } = await getContractUpdates(payload);

      setData(data?.contracts);
      setTotal(data?.total);
      setLoading(false);
    } catch (error) {
      console.log("err", error);
      setLoading(false);
    }
  };

  // columns
  //    | generators for cell of table
  const operatorColumnGenerator = (row, column) => {
    const operators = [
      new Operator({
        icon: <RemoveRedEye />,
        onClick: () => handleOpenDialog(row),
        type: 0,
      }),
    ];

    return <OperatorGenerator operators={operators} />;
  };

  const statusColumnGenerator = (row, col) => {
    const approvers = [];
    row.verifiers?.map((item, index) => {
      approvers.push({
        index: index + 1,
        employee:
          item.employee?.firstname + " " + item.employee?.lastname || "---",
        status: item.status,
      });
    });

    return (
      <StatusGenerator
        approvement={approvers}
        docType={"contUpdate"}
        value={getDescendantProp(row, col.path)}
      />
    );
  };

  const downloadHandler = async (columnsForExport) => {
    try {
      const forExport = [];
      const isWithFilter =
        filters.length > 0 && filters[0]?.column?.length > 0 ? true : false;
      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;

        forExport.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      const encodedColumns = encodeURIComponent(JSON.stringify(forExport));
      const encodedFilter = encodeURIComponent(JSON.stringify(filters));
      const resp = await downloadContractChangesCSV({
        isWithFilter: isWithFilter,
        columns: encodedColumns,
        filter: encodedFilter,
      });
      resp && downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };

  // handlers
  const handleOpenDialog = (row) => {
    setSelectedId(row._id);
    setShowModal(true);
  };

  // page consstant params

  const headerData = {
    title: "Əmək müqavilələrinə dəyişiklik",
    variant: "h3",
  };
  return (
    <>
      {/* header */}
      <Grid>
        <Grid>
          <Header data={headerData} />
        </Grid>
      </Grid>

      {/* Statistic */}
      <StatisticaContChanges />

      {/* button for edit employment contracts */}

      {/* Table */}
      <Table
        columns={empConstColumns(
          getDescendantProp,
          statusColumnGenerator,
          operatorColumnGenerator
        )}
        data={data}
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        isLoading={loading}
        applyFiltersHandler={() => {}}
        cancelAppliedFilters={() => {}}
        downloadHandler={downloadHandler}
        addRightContButtons={[
          <Grid style={{ marginLeft: "5px", height: "100%" }}>
            <ButtonDropdown
              right={0}
              title={"Ə.M. Dəyişiklik et"}
              dropdownComponent={
                <DropdownContainer>
                  <Element
                    onClick={() => {
                      setIndividualModal(true);
                    }}
                  >
                    İndividual
                  </Element>
                  <Element
                    onClick={() => {
                      setGeneralModal(true);
                    }}
                  >
                    Kütləvi
                  </Element>
                </DropdownContainer>
              }
            />
          </Grid>,
        ]}
        totalRows={total}
      />

      {individualModal && (
        <EmpContsDialog
          fetchData={fetchData}
          open={individualModal}
          setOpen={setIndividualModal}
        />
      )}

      {generalModal && (
        <EmpConstsGeneralDialog open={generalModal} setOpen={setGeneralModal} />
      )}
      {showModal && (
        <ContChangesModal
          open={showModal}
          id={selectedId}
          onClose={() => {
            setSelectedId("");
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};

export default EmpContsChanges;
