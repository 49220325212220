import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { formatDateTime } from "../../../../utils/helperFunctions";
import {
  GROUP_NAME,
  LABOUR_CONTRACTS_CUSTOM_RENDERS_METHODS,
} from "../dialogConstants";
import FieldItem from "./parts/FieldItem";
import { isArray } from "lodash";
import moment from "moment";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
export default function DialogLeftCont({
  contractType,
  fieldsData,
  isGroup = false,
  mode = "noneditable",
  editBtnOnClick,
  inputProps,
  deleteBtnOnClick,
}) {
  const [searchList, setSearchList] = useState();

  const findKeyGroup = (key) => {
    switch (key) {
      case "labourPeriod":
        return "labour_period";
      case "wage":
        return "for_wage";
      case "protection":
        return "for_protection";
      case "workVacationTime":
        return "for_work_vacation_time";
      case "vacation":
        return "for_vacation";
      case "physicalEducationSport":
        return "physical_education_sport";
      case "socialProtection":
        return "social_protection";
      case "regulationPropertyRelation":
        return "regulation_property_relations";
      default:
        return key;
    }
  };

  const renderFields = (updateData) => {
    if (!fieldsData) return;

    if (isGroup) {
      return handlerOnGroup(updateData);
    }
    return handlerOnSingle();
  };

  const updateRenderField = (field, index) => {
    let newVal;
    const label = field.label ? field.label : "__";
    const transformedValues = {
      indefinite: "Müddətsiz",
      term: "Müddətli",
      monday: "Bazar ertəsi",
      tuesday: "Çərşənbə axşamı",
      wednesday: "Çərşənbə",
      thursday: "Cümə axşamı",
      friday: "Cümə",
      saturday: "Şənbə",
      sunday: "Bazar",
      monthly: "Aylıq",
      no: "Xeyr",
      yes: "Bəli",
    };

    if (field?.type === "array") {
      newVal = field?.value[index]?.staff?.employee
        .map((emp) => {
          return `${emp.lastname} ${emp.firstname} ${emp.fathername}`;
        })
        .filter(Boolean)
        .join(" -> ");
    }
    if (field?.type === "date") {
      if (field.value) {
        const isValidDate = (date) => {
          return moment(date, moment.ISO_8601, true).isValid();
        };

        newVal = Object.values(field?.value)
          .map((val) => {
            if (val === " ") return "__";
            if (field.type !== "number") {
              if (isValidDate(val)) return moment(val).format("DD/MM/YYYY");
              if (transformedValues[val]) return transformedValues[val];
            }

            return val;
          })
          .filter(Boolean)
          .join(" -> ");
      } else {
        return "";
      }
    }
    if (field.type === "number") {
      console.log(field.value);
      return (
        <>
          <div style={{ fontSize: "20px" }}>
            {field?.value?.old}
            <ArrowForwardIcon /> {field?.value?.new}
          </div>
        </>
      );
    }
    if (field.isHide)
      // if (field?.label === "Əməkdaşa verilən mükafat vaxtı") {
      //   newVal = transformedValues[field?.value];
      //   return newVal;
      // }

      return;
    const value =
      typeof field.value && field.type === "object"
        ? newVal
        : renderValue(field, transformedValues);
    return <FieldItem key={index} label={label} value={value} index={index} />;
  };
  const handlerOnGroup = (updateData) => {
    const uiParts = [];
    const data = { ...fieldsData };
    let updateKeys;
    if (data.updates) {
      updateKeys = Object.keys(data.updates);
      for (let element of updateKeys) {
        data[element] = data.updates[element];
      }
    }
    for (const key in data) {
      const group = data[key];
      const utils =
        LABOUR_CONTRACTS_CUSTOM_RENDERS_METHODS[contractType]?.[key];

      uiParts.push(
        <Accordion sx={{ backgroundColor: "rgba(0,0,0,0.1)" }} key={key}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
                color: "rgba(200,200,200,1)",
              }}
            >
              {GROUP_NAME[key]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ marginX: "10px" }}>
            {isArray(group) &&
              group.map((item, index) => {
                return updateRenderField(
                  {
                    ...item,
                    renderValue: utils && utils[item.key],
                    group: group,
                    key: key,
                  },
                  index
                );
              })}
          </AccordionDetails>
        </Accordion>
      );
    }

    if (updateData) {
      const renderArray = uiParts.filter((element) =>
        updateKeys?.includes(element.key)
      );
      return [...renderArray];
    } else {
      const renderArray = uiParts.filter(
        (element) => !updateKeys?.includes(element.key)
      );
      const renderedArray = renderArray.filter(
        (element) => !["updates"].includes(element.key)
      );
      return [...renderedArray];
    }

    // return uiParts;
  };
  const handlerOnSingle = () => {
    return fieldsData?.map((item, index) => {
      if (Array.isArray(item)) {
        return item.map((itm) => {
          return renderField(itm, `${itm.label}/${index}`);
        });
      }
      return renderField(item, `${item[index].label}/${index}`);
    });
  };

  const renderField = (field, index) => {
    if (field.isHide) return;

    const label = field.label ? field.label : "__";
    const value = renderValue(field);

    return (
      <FieldItem
        key={index}
        label={label}
        value={value}
        mode={mode}
        index={index}
        group={{ [field.key]: field.group }}
        editBtnOnClick={editBtnOnClick}
        inputProps={inputProps}
        deleteBtnOnClick={deleteBtnOnClick}
      />
    );
  };

  const renderValue = (field, transformedValues) => {
    if (!field.value) return "-";
    if (field?.label === "Əməkdaşa verilən mükafat vaxtı") {
      return transformedValues[field?.value];
    } else if (field?.label === "Nəqliyyatla təmin edilir mi?") {
      return transformedValues[field?.value];
    }
    if (field.renderValue) {
      const render = field.renderValue;
      return render(field.value);
    }
    switch (field.type) {
      case "string":
        return field.value;
      case "date":
        if (!field.value) {
          return "__";
        }
        return formatDateTime(field.value);
      case "array":
        return "array";
      case "object":
        return "object";
      default:
        return field.value;
    }
  };
  return (
    <Grid sx={styles.container}>
      <Grid>{renderFields(false)}</Grid>
      {fieldsData.updates && (
        <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <p style={{ textAlign: "center", width: "100%", fontSize: 18 }}>
            Dəyişikliklər
          </p>
        </div>
      )}
      <Grid>{renderFields(true)}</Grid>
    </Grid>
  );
}

const styles = {
  container: {
    width: "100%",
    height: "100%",
    paddingX: "10px",
    overflow: "auto",
  },
};
