/* eslint-disable prettier/prettier */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { getAllEmployees } from "../../api/api";
import ContractDialog from "../../components/dialogs/documentDialog/ContractDialog";
import { downloadFile } from "../../utils/downloadCSV";
import Header from "../../components/layouts/header/Header";
import OptionGroup from "../../components/OptionGroup/OptionGroup";
import StatusGenerator from "../../components/table/StatusGenerator";
import Table from "../../components/table/Table";
import {
  DISSMISAL_FOR_FILTER,
  OPERATORS_CONTAINING,
  clauses,
} from "../../constants";
import Column from "../../models/table/Column";
import { getContractsStatistics } from "../../services/documentations/EmploymentContractsService";
import { getCommand } from "../../services/employees/EmployeeServices";
import {
  addExplanation,
  addExplanationAsFile,
  addRemoval,
  approveRejectStatus,
  create_order_removal,
  download,
  downloadRemovalApps,
  getRemovals,
  preview_removal,
  updateRemoval,
} from "../../services/leaves/LeaveServices";
import { getDescendantProp, normalizeDate } from "../../utils/helperFunctions";
import handleFile from "../../utils/handleFile";
import "./style.css";
import OperatorGenerator from "../../components/table/OperatorGenerator";
import Operator from "../../models/table/Operator";
import {
  Close,
  RemoveRedEye,
  TurnSlightRight,
  Upload,
  Loupe,
} from "@mui/icons-material";
import CustomDialog from "./Dialog";

export default function Removal() {
  const [isLoading, setIsLoading] = useState(false);
  const [show_Accordion, set_show_Accordion] = useState(false);
  const [currMonthStat, setCurrMonthStat] = useState([]);
  const [filter, setFilter] = useState([]);
  const [offset, setOffset] = useState();
  const [limit, setLimit] = useState(5);
  const [total, setTotal] = useState();
  const [isLoadingCS, setIsLoadingCS] = useState(false);
  const [isLoadingGS, setIsLoadingGS] = useState(false);
  const [create_order_modal, set_create_order_modal] = useState(false);
  const [is_sent_status, set_is_sent_status] = useState(false);
  const [status_description, set_status_description] = useState("");
  const [selected_row, set_selected_row] = useState(0);
  const [preview_data, set_preview_data] = useState({});
  const [view_order_detail, set_view_order_detail] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [all_orders, set_all_orders] = useState([]);
  const [show_version_dialog, set_show_version_dialog] = useState(false);
  const [version_data, set_version_data] = useState({});
  const [employee, set_employee] = useState({});
  const [create_btn_disable, set_create_btn_disable] = useState(false);
  const [loading, set_loading] = useState(false);
  const [leave_state, set_leave_state] = useState({
    reason: "",
    employeeId: "",
    startDate: "",
    endDate: "",
    clause: [],
  });
  const [all_leaves, set_all_leaves] = useState({
    count: 0,
    records: [],
  });
  const [show_dialog, set_show_dialog] = useState(false);
  let default_ = {
    column: "docModel",
    operator: "equalsTo",
    value: "ExclusionApplication",
  };
  const handleStartDateChange = (date) => {
    if (date && moment(date).isAfter(leave_state.endDate)) {
      setStartDateError(true);
      return;
    } else {
      setStartDateError(false);
    }
    set_leave_state({ ...leave_state, startDate: date });
  };

  const handleEndDateChange = (date) => {
    if (date && moment(date).isBefore(leave_state.startDate)) {
      setEndDateError(true);
      return;
    } else {
      setEndDateError(false);
    }
    set_leave_state({ ...leave_state, endDate: date });
  };

  useEffect(() => {
    let filterReady;
    if (filter.length > 0) {
      let filtered = filter?.map((f) => {
        return {
          column: f?.column,
          operator: f?.operator,
          value: f?.value,
        };
      });
      filtered.push(default_);
      filterReady = encodeURIComponent(JSON.stringify(filtered));
    } else {
      filterReady = encodeURIComponent(JSON.stringify(default_));
    }
    if (filter.length > 0) {
      async function getData() {
        try {
          setIsLoading(true);

          let response = await getRemovals({
            offset: offset || 0,
            limit: limit || 5,
            filter: filterReady,
          });
          let records = response?.records?.map((r) => ({
            ...r,
            status: r?.status,
            createdOrder: r?.originalApplication?.order,
            updated: r?.updated,
            activity: r?.activity?.status,
            createdBy: r?.createdBy,
            clause: r?.originalApplication?.clause,
            endDate: r?.originalApplication?.endDate,
            startDate: r?.originalApplication?.startDate,
            reason: r?.originalApplication?.reason,
            id: r?._id,
            firstname: r?.employee?.firstname,
            lastname: r?.employee?.lastname,
            fathername: r?.employee?.fathername,
          }));
          set_all_leaves({ ...all_leaves, records, count: response?.count });
          setTotal(response.total);
        } catch (error) {
          console.log(error, "err");
        } finally {
          setIsLoading(false);
        }
      }
      getData();
    } else {
      get_removals();
    }
  }, [filter, offset, limit]);

  const downloadTableCSVHandler = async (columnsForExport) => {
    try {
      const forExport = [];
      const isWithFilter =
        filter.length > 0 && filter[0]?.column?.length > 0 ? true : false;
      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;

        forExport.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      const encodedColumns = encodeURIComponent(JSON.stringify(forExport));
      const encodedFilter = encodeURIComponent(JSON.stringify(filter));
      const resp = await downloadRemovalApps({
        isWithFilter: isWithFilter,
        columns: encodedColumns,
        filter: encodedFilter,
      });
      resp && downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };

  const add_removal = async () => {
    set_loading(true);
    // try {
    let response = await addRemoval(
      leave_state,
      encodeURIComponent(JSON.stringify([default_])),
      enqueueSnackbar,
      set_loading
    );
    if (response) {
      enqueueSnackbar("Kənarlaşdırma müvəffəqiyyətlə yaradıldı", { variant: "success" });
      set_leave_state({
        reason: "",
        employeeId: "",
        startDate: "",
        endDate: "",
        clause: [],
      });
      set_show_Accordion(false);
      get_removals();
      set_loading(false);
    }
    // } catch (error) {
    //   enqueueSnackbar("Error occured", { variant: "error" });
    //   set_loading(false);
    // }
  };
  const update_removal = async () => {
    try {
      set_loading(true);
      let response = await updateRemoval(leave_state?.id, leave_state);
      if (response) {
        enqueueSnackbar("Müvəffəqiyyətlə yeniləndi", { variant: "success" });
        set_leave_state({
          reason: "",
          employeeId: "",
          startDate: "",
          endDate: "",
          clause: [],
        });
        set_show_Accordion(false);
        get_removals();
        set_loading(false);
      }
    } catch (error) {
      enqueueSnackbar("Error occured", { variant: "error" });
      set_loading(false);
    }
  };
  const get_removals = async () => {
    try {
      setIsLoading(true);

      let response = await getRemovals({
        offset: offset || 0,
        limit: limit || 5,
        filter: encodeURIComponent(JSON.stringify([default_])),
      });
      let records = response?.records?.map((r) => ({
        ...r,
        status: r?.status,
        createdOrder: r?.originalApplication?.createdOrder?.order,
        updated: r?.updated,
        activity: r?.activity,
        createdBy: r?.createdBy,
        oldVersions: r?.oldVersions,
        clause: r?.originalApplication?.clause,
        endDate: r?.originalApplication?.endDate,
        startDate: r?.originalApplication?.startDate,
        reason: r?.originalApplication?.reason,
        id: r?._id,
        firstname: r?.employee?.firstname,
        lastname: r?.employee?.lastname,
        fathername: r?.employee?.fathername,
      }));
      set_all_leaves({ ...all_leaves, records, count: response?.count });
      setTotal(response.total);
    } catch (error) {
      console.log(error, "err");
    } finally {
      setIsLoading(false);
    }
  };

  const send_explanation = async (id) => {
    try {
      let response = await addExplanation(id);
      if (response) {
        // enqueueSnackbar("İzahat göndərildi", { variant: "success" });
        enqueueSnackbar(
          `${response?.application?.employee?.firstname} ${response?.application?.employee?.lastname}' ə izahat sorğusu göndərildi`,
          { variant: "success" }
        );
        get_removals();
      }
    } catch (error) {
      console.log(error, "err");
    }
  };

  const add_explanation_as_file = async (files, id) => {
    const base64Res = await handleFile(files[0]);
    try {
      let response = await addExplanationAsFile(id, base64Res?.base64);
      if (response) {
        enqueueSnackbar("İzahat göndərildi", { variant: "success" });
        get_removals();
      }
    } catch (error) {
      console.log(error, "err");
    }
  };
  const handleApproveStatus = async (id, status) => {
    try {
      let response = await approveRejectStatus(id, status);
      if (response) {
        enqueueSnackbar("Təsdiq göndərildi", { variant: "success" });
        set_show_dialog(false);
        set_show_version_dialog(false);
        set_is_sent_status(false);
        get_removals();
      }
    } catch (error) {
      console.log(error, "err");
    }
  };
  const handleRejectStatus = async (id, status) => {
    try {
      let response = await approveRejectStatus(id, status, status_description);
      if (response) {
        enqueueSnackbar("İmtina göndərildi", { variant: "success" });
        set_show_dialog(false);
        set_show_version_dialog(false);
        set_is_sent_status(false);
        get_removals();
      }
    } catch (error) {
      console.log(error, "err");
    }
  };
  const download_file = async (id, mimeType, nameUsingDocNumber) => {
    try {
      let response = await download(id);
      if (response) {
        downloadFile(response, nameUsingDocNumber, mimeType);
        set_show_version_dialog(false);
      }
    } catch (error) {
      enqueueSnackbar("Xəta baş verdi", { variant: "error" });
      console.log(error, "err");
    }
  };
  useEffect(() => {
    set_create_btn_disable(
      !leave_state?.clause?.length ||
      leave_state?.reason === "" ||
      leave_state?.startDate === "" ||
      leave_state?.endDate === "" ||
      leave_state?.employeeId === ""
    );
    if (startDateError || endDateError) {
      set_create_btn_disable(true);
    }
  }, [leave_state, startDateError, endDateError]);

  useEffect(() => {
    //general statistic
    getEmployees();
    get_removals();
    getCurrMonthStatistics();
  }, []);

  //fetch data
  const getEmployees = async () => {
    const employeeFilter = [
      {
        "column": "status",
        "operator": "equalsTo",
        "value": "active"
      }
    ];

    try {
      setIsLoadingGS(true);
      const response = await getAllEmployees("", "", encodeURIComponent(JSON.stringify(employeeFilter)));
      if (response?.data?.employees) {
        let emp = response?.data?.employees?.map((e) => ({
          firstname: e?.firstname,
          lastname: e?.lastname,
          fathername: e?.fathername,
          id: e?._id,
        }));
        set_employee(emp);
      }

      setIsLoadingGS(false);
    } catch (error) {
      console.log("general statistics", error);
      setIsLoadingGS(false);
    }
  };


  const getCurrMonthStatistics = async () => {
    try {
      setIsLoadingCS(true);
      const startDate = new Date();
      startDate.setDate(1);
      const endDate = new Date();

      const startDateFormatted = `${startDate.getMonth() + 1
        }/${startDate.getDate()}/${startDate.getFullYear()}`;
      const endDateFormatted = `${endDate.getMonth() + 1
        }/${endDate.getDate()}/${endDate.getFullYear()}`;

      const { data } = await getContractsStatistics({
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      });
      data &&
        setCurrMonthStat({
          approved: data.approved,
          pending: data.pending,
          rejected: data.rejected + data.expiringContracts,
        });
      setIsLoadingCS(false);
    } catch (error) {
      console.log("current statistics", error);
      setIsLoadingCS(false);
    }
  };


  const handle_view = (id) => {
    set_selected_row(id);
    set_show_dialog(true);
  };

  const handle_version_dialog = (version_data) => {
    set_show_version_dialog(true);
    set_version_data(version_data);
  };

  const handle_preview_order = async (id) => {
    try {
      let response = await preview_removal(id);
      if (response) {
        set_preview_data(response);
        set_selected_row(id);
        set_create_order_modal(true);
      }
    } catch (error) {
      console.log(error, "err");
    }
  };

  const handle_create_order = async (id) => {
    try {
      let response = await create_order_removal(id);
      if (response) {
        get_removals();
        set_create_order_modal(false);
        enqueueSnackbar("Əmr uğurla yaradıldı", { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar("Xəta baş verdi", { variant: "error" });
      console.log(error, "err");
    }
  };

  const handle_preview_order_detail = async (orders) => {
    set_all_orders(orders);
    set_view_order_detail(orders?.length ? true : false);
  };

  const columns = [
    new Column({
      path: "employee.firstname",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.lastname",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.fathername",
      headerName: "Ata adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "originalApplication.startDate",
      headerName: "Başlama tarixi",
      filterType: OPERATORS_CONTAINING.DATE,

      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "originalApplication.endDate",
      headerName: "Bitmə tarixi",
      filterType: OPERATORS_CONTAINING.DATE,

      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "originalApplication.clause",
      headerName: "ƏM Əsası",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "originalApplication.reason",
      headerName: "Səbəbi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "status",
      headerName: "Cari status",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: DISSMISAL_FOR_FILTER,

      renderCell: (row, column) => {
        return (
          <StatusGenerator
            value={getDescendantProp(row, column.path)}
            docType={"dissmisal"}
          />
        );
      },
    }),
    new Column({
      path: "#",
      headerName: "Operator",
      isStaticColumn: true,
      unhidableColumn: true,
      disableColSearch: true,
      widthOfColumn: 200,
      renderCell: (row, col) => {
        let send_dis = false;
        let upload_dis = false;
        let status = row?.status;

        if (status === "rejected") {
          send_dis = false;
          upload_dis = false;
        } else {
          switch (status) {
            case "explanationSentByEmployee":
              send_dis = true;
              upload_dis = true;
              break;
            case "requestedToEmployee":
              send_dis = true;
              upload_dis = true;
              break;
            case "explanationSentByManager":
              send_dis = true;
              upload_dis = false;
              break;
            case "rejectedOrderApprove":
              send_dis = true;
              upload_dis = true;
              break;
            default:
              break;
          }
        }
        let operators =
          status === "pendingOrder"
            ? [
              new Operator({
                icon: (
                  <Tooltip title="Bax">
                    <RemoveRedEye />
                  </Tooltip>
                ),
                onClick: () => {
                  handle_view(row._id);
                },
              }),
              new Operator({
                icon: (
                  <Tooltip title="Əmr yarat">
                    <Button
                      disabled={send_dis}
                      style={{
                        border: 0,
                        background: "unset",
                        color: send_dis ? "#ffffff29" : "#407ad6",
                      }}
                    >
                      Əmr yarat
                    </Button>
                  </Tooltip>
                ),
                onClick: () => handle_preview_order(row?._id),
              }),
            ]
            : status === "pendingOrderApprove" || status === "active"
              ? [
                new Operator({
                  icon: (
                    <Tooltip title="Bax">
                      <RemoveRedEye />
                    </Tooltip>
                  ),
                  onClick: () => {
                    handle_view(row._id);
                  },
                }),
                new Operator({
                  icon: (
                    <Tooltip title="Əmrlərə bax">
                      <Button
                        disabled={send_dis}
                        style={{
                          border: 0,
                          background: "unset",
                          color: send_dis ? "#ffffff29" : "#407ad6",
                        }}
                      >
                        Əmrə bax
                      </Button>
                    </Tooltip>
                  ),
                  onClick: () =>
                    handle_preview_order_detail(row?.originalApplication?.createdOrders?.map((o) => o?.order?.data)),
                }),
              ]
              : [
                new Operator({
                  icon: (
                    <Tooltip title="Bax">
                      <RemoveRedEye />
                    </Tooltip>
                  ),
                  onClick: () => {
                    handle_view(row._id);
                  },
                }),
                new Operator({
                  icon: (
                    <Tooltip title="Yönləndir">
                      <button
                        disabled={send_dis}
                        style={{
                          border: 0,
                          background: "unset",
                          color: send_dis ? "#ffffff29" : "#407ad6",
                        }}
                      >
                        <TurnSlightRight />
                      </button>
                    </Tooltip>
                  ),
                  onClick: () => send_explanation(row.id),
                }),
                new Operator({
                  icon: (
                    <Tooltip title="Yüklə">
                      <div class="wrapper">
                        <div
                          class="file-upload"
                          style={{
                            color: upload_dis ? "#ffffff29" : "#407ad6",
                          }}
                        >
                          <input
                            type="file"
                            disabled={upload_dis}
                            accept="application/pdf,application/msword,
          application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            onChange={(e) =>
                              add_explanation_as_file(e.target.files, row?.id)
                            }
                          />
                          <Upload />
                        </div>
                      </div>
                      <button
                        disabled={upload_dis}
                        style={{
                          display: "none",
                          border: 0,
                          background: "unset",
                          color: upload_dis ? "#ffffff29" : "#407ad6",
                        }}
                      >
                        <Upload />
                      </button>
                    </Tooltip>
                  ),
                  // onClick: () => add_explanation_as_file(row?.id)
                }),
              ];

        return <OperatorGenerator operators={operators} />;
      },
    }),
  ];

  const headerData = {
    title: "Kənarlaşdırma Reyestri",
    variant: "h3",
  };

  const handle_clause_item = (value) => {
    // let filtered = clauses.find(or => or?.childs?.includes(e.target.value));
    let prev = [...leave_state?.clause];
    if (prev.find((p) => p === value) !== undefined) {
      prev = prev.filter((p) => p !== value);
    } else {
      prev.push(value);
    }
    set_leave_state({ ...leave_state, clause: prev });
  };
  let selected_row_ = all_leaves?.records?.find((r) => r?.id === selected_row);

  let view_order_button = [
    {
      text: "Bağla",
      onClick: () => set_view_order_detail(false),
    },
  ];

  let create_order_button = [
    {
      text: "Ləğv et",
      onClick: () => set_create_order_modal(false),
    },
    {
      text: "Əmr Yarat",
      onClick: () => handle_create_order(selected_row),
    },
  ];
  return (
    <React.Fragment>
      <Box>
        <Grid>
          <Grid>
            <Grid>
              <Header data={headerData} />
            </Grid>
          </Grid>
        </Grid>
        <div className="d-flex justify-content-end">
          <Button
            title="Kənarlaşdırma yarat"
            onClick={() => set_show_Accordion(!show_Accordion)}
            variant="text"
            sx={{ height: "100%" }}
          >
            <Loupe />
          </Button>
        </div>
        {show_Accordion && (
          <>
            <div className="d-flex">
              <div className="w-25">
                <div>Əməkdaş</div>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Əməkdaş
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={leave_state?.employeeId}
                      label="Əməkdaş"
                      placeholder="ok"
                      onChange={(e) =>
                        set_leave_state({
                          ...leave_state,
                          employeeId: e.target.value,
                        })
                      }
                    >
                      {employee?.length &&
                        employee?.map((e) => (
                          <MenuItem value={e?.id}>
                            {e?.firstname} {e?.lastname} {e?.fathername}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="w-25 ms-2">
                <div>Səbəbi</div>
                <div className="mt-2">
                  <TextField
                    onChange={(e) =>
                      set_leave_state({
                        ...leave_state,
                        reason: e.target.value,
                      })
                    }
                    label="Səbəbi"
                    value={leave_state?.reason}
                    fullWidth
                  />
                </div>
              </div>
              <div className="w-25 ms-2">
                <div>Başlama tarixi</div>
                <div className="mt-2">
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label="Başlama tarixi"
                    value={leave_state?.startDate || null}
                    // maxDate={new Date()}
                    minDate={new Date(new Date().getTime() - 86400000 * 30)}
                    onChange={(date) => {
                      handleStartDateChange(date);
                      // set_leave_state({ ...leave_state, startDate: date })
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                  {startDateError && (
                    <FormHelperText error>
                      Başlama tarixi bitmə tarixindən böyük ola bilməz
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className="w-25 ms-2">
                <div>Bitmə tarixi</div>
                <div className="mt-2">
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label="Bitmə tarixi"
                    value={leave_state?.endDate || null}
                    // maxDate={new Date()}
                    minDate={new Date(new Date().getTime() - 86400000 * 30)}
                    onChange={(date) => {
                      handleEndDateChange(date);
                      // set_leave_state({ ...leave_state, endDate: date })
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                  {endDateError && (
                    <FormHelperText error>
                      Bitmə tarixi başlanğıc tarixindən kiçik ola bilməz
                    </FormHelperText>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 mt-3">
              <div>Əmək məcəlləsinin maddəsi</div>
              <div className="mt-2">
                <OptionGroup
                  value={leave_state?.clause}
                  set_value={handle_clause_item}
                  set_leave_state={set_leave_state}
                  data={clauses}
                  default_label="Ə.M maddəsi Seçin"
                />
              </div>
            </div>
            <div className="mt-3">
              {loading && <LinearProgress color="secondary"
                className="mb-2"
              />}
              <Button
                variant="contained"
                onClick={leave_state?.id ? update_removal : add_removal}
                disabled={loading || create_btn_disable}
              >
                {leave_state?.id ? "Redaktə et" : "Yarat"}
              </Button>
            </div>
          </>
        )}
        <div className="mt-4">
          <Table
            columns={columns}
            data={all_leaves.records || []}
            totalRows={total}
            offset={offset}
            setOffset={(value) => setOffset(value)}
            limit={limit}
            setLimit={(value) => setLimit(value)}
            isLoading={isLoading}
            applyFiltersHandler={(filter) => setFilter(filter)}
            cancelAppliedFilters={() => setFilter([])}
            downloadHandler={(cols) => downloadTableCSVHandler(cols)}
          />
        </div>
        <Dialog
          open={show_dialog}
          onClose={() => set_show_dialog(false)}
          aria-labelledby="alert-dialog-title"
          className="root-d-2"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Hamısına bax</DialogTitle>
          <DialogContent>
            <div className="d-flex flex-column">
              <Button
                variant="contained"
                onClick={() =>
                  handle_version_dialog({
                    application: {
                      firstname: selected_row_?.firstname,
                      lastname: selected_row_?.lastname,
                      fathername: selected_row_?.fathername,
                      reason: selected_row_?.reason,
                      clause: selected_row_?.clause,
                      startDate: selected_row_?.startDate,
                      endDate: selected_row_?.endDate,
                      id: selected_row_?.id,
                    },
                    activity: selected_row_?.activity,
                    status: selected_row_?.status,
                    version: "Son",
                  })
                }
              >
                Son versiya
              </Button>
              {selected_row_?.oldVersions?.length > 0 &&
                selected_row_?.oldVersions?.map((old, i) => (
                  <Button
                    className="mt-2"
                    variant="contained"
                    onClick={() =>
                      handle_version_dialog({
                        application: {
                          firstname: selected_row_?.firstname,
                          lastname: selected_row_?.lastname,
                          fathername: selected_row_?.fathername,
                          reason: old?.originalApplication?.reason,
                          clause: old?.originalApplication?.clause,
                          startDate: old?.originalApplication?.startDate,
                          endDate: old?.originalApplication?.endDate,
                          id: selected_row_?.id,
                        },
                        activity: selected_row_?.activity,
                        status: selected_row_?.status,
                        version: "Köhnə",
                      })
                    }
                  >
                    Köhnə versiya - {1}
                  </Button>
                ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => set_show_dialog(false)}>Bağla</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={show_version_dialog}
          onClose={() => set_show_version_dialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="root-d"
        >
          <DialogTitle id="alert-dialog-title" className="text-center">
            {version_data?.version} versiya
          </DialogTitle>
          <DialogContent>
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <div>Ad:</div>
                <div>{version_data?.application?.firstname}</div>
              </div>
              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <div>Soyad:</div>
                <div>{version_data?.application?.lastname}</div>
              </div>
              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <div>Ata Adı:</div>
                <div>{version_data?.application?.fathername}</div>
              </div>
              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <div>Səbəb:</div>
                <div>{version_data?.application?.reason}</div>
              </div>
              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <div>Başlama tarixi:</div>
                <div>
                  {moment(version_data?.application?.startDate).format(
                    "MM-DD-YYYY, h:mm:ss"
                  )}
                </div>
              </div>
              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <div>Bitmə tarixi:</div>
                <div>
                  {moment(version_data?.application?.endDate).format(
                    "MM-DD-YYYY, h:mm:ss"
                  )}
                </div>
              </div>

              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <div>ƏM Maddələri:</div>
                <div className="w-75">
                  {version_data?.application?.clause?.length &&
                    version_data?.application?.clause?.map((c) => (
                      <>
                        <div>{c}</div>
                        <hr />
                      </>
                    ))}
                </div>
              </div>
            </div>
            <div className="d-flex">
              {version_data?.activity?.explanationAsFile && (
                <Button
                  style={{
                    width: !is_sent_status ? "49%" : "80%",
                    margin: "0 auto",
                  }}
                  variant="contained"
                  onClick={() =>
                    download_file(
                      version_data?.application?.id,
                      version_data?.activity?.explanationAsFile?.mimeType,
                      version_data?.activity?.explanationAsFile.docNumber
                    )
                  }
                >
                  İzahatı yüklə
                </Button>
              )}
              {version_data?.version === "Son" &&
                version_data?.status === "explanationSentByEmployee" && (
                  <Button
                    style={{
                      width: !is_sent_status ? "49%" : "",
                      margin: "0 auto",
                    }}
                    variant="contained"
                    onClick={() => set_is_sent_status(!is_sent_status)}
                  >
                    {!is_sent_status ? "Cavabla" : <Close />}
                  </Button>
                )}
            </div>
            <div>
              {is_sent_status && (
                <div className="p-3">
                  Açıqlama:
                  <TextField
                    fullWidth
                    multiline
                    value={status_description}
                    onChange={(e) => set_status_description(e.target.value)}
                    className="mt-2"
                  />
                  <DialogActions>
                    <Button
                      disabled={!status_description?.length}
                      variant="contained"
                      onClick={() =>
                        handleApproveStatus(
                          version_data?.application?.id,
                          "approve"
                        )
                      }
                    >
                      Təsdiqlə
                    </Button>
                    <Button
                      disabled={!status_description?.length}
                      style={{
                        background: status_description?.length ? "red" : "",
                      }}
                      variant="contained"
                      onClick={() =>
                        handleRejectStatus(
                          version_data?.application?.id,
                          "reject"
                        )
                      }
                    >
                      Rədd et
                    </Button>
                  </DialogActions>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => set_show_version_dialog(false)}>
              Bağla
            </Button>
          </DialogActions>
        </Dialog>
        <CustomDialog
          data={preview_data}
          view_dialog={{
            set: set_create_order_modal,
            state: create_order_modal,
          }}
          buttons={create_order_button}
          title="Əmr Yarat"
          type="create-order"
        />
        {all_orders.length !== 0 && (
          <CustomDialog
            data={all_orders}
            view_dialog={{
              set: set_view_order_detail,
              state: view_order_detail,
            }}
            buttons={view_order_button}
            title="Əmrə Bax"
            type="preview-order"
          />
        )}
      </Box>
    </React.Fragment>
  );
}
