import { Button, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  COLORS_STATUS,
  PUBLIC_URL,
  REQUESTS_STATUS_TYPE,
} from "../../../constants";
import { getEmployeeRequestManager } from "../../../services/vacation/VacationRequest";
import DocumentDialog from "./DocumentDialog";

export default function RequestDialog({
  open,
  onClose,
  id,
  fetchHandler = undefined,
  data = {},
  handlerUpdateReq,
}) {
  const [isLoading, setIsLoading] = useState(true);

  const [contractData, setContractData] = useState(data);
  const [comments, setComments] = useState([]);
  const [fields, setFields] = useState([]);
  const [status, setStatus] = useState();
  const [navList, setNavList] = useState();
  const [isGroupFields, setIsGroupFields] = useState(false);
  const [versionsList, setVersionsList] = useState([]);
  const [currVersion, setCurrVersion] = useState("");

  const { currentEmployee } = useSelector((state) => state.commonThings);

  useEffect(() => {
    getData(id);
  }, []);

  useEffect(() => {
    generateData();
    Object.keys(contractData).length > 0 && setIsLoading(false);
  }, [contractData]);

  const getData = async (_id) => {
    // if (!id || !fetchHandler) return;
    try {
      const { data } = fetchHandler
        ? await fetchHandler(_id, true)
        : await getEmployeeRequestManager(_id, true);
      data && setContractData(data);
    } catch (error) {
      console.log("err", error);
    }
  };

  const generateData = () => {
    if (contractData.length === 0) return;
    // generateFields(contractData);
    generateStatus(contractData);
    generateNavbarList(contractData);
    generateComments(contractData);
    generateFooter(contractData);
    generateVersions(data);

    setFields(contractData.data);
  };

  const generateVersions = (data) => {
    if (versionsList?.length > 0) return;
    const list = [];
    const versions = data.oldVersions;

    if (versions?.length === 0) {
      list.push({
        label: "1",
        value: id,
      });
    } else {
      versions?.map((item, index) => {
        list.push({
          label: `${index + 1}`,
          value: item.contract._id,
        });
      });

      list.push({
        label: `${versions?.length + 1}`,
        value: id,
      });
    }

    setCurrVersion(list[list.length - 1].value);

    setVersionsList(list);
  };

  const generateNavbarList = (data) => {
    if (data?.docModel === "EmploymentRequest") {
      const navList = [
        {
          label: "Sorğu",
          value: 0,
        },
        { label: "Namizəd haqqında", value: data.originalRequest.employee.id },
      ];
      setNavList(navList);
    }
  };

  const generateFields = (data) => {
    const docType = data?.docModel;
    switch (docType) {
      case "VacationRequest":
        handlerFieldVacationRequest(data);
        break;
      case "EmploymentRequest":
        handlerFieldEmploymentRequest(data);
        break;
      case "StructureRequest":
        handlerFieldStructure(data);
        break;
      default:
        break;
    }
  };
  const generateStatus = (data) => {
    const isRejected = isRejectedHandler(data);
    if (isRejected) {
      setStatus(isRejected);
      return;
    }

    const loggedStatus = checkLoggedUser(data);
    if (loggedStatus) {
      if (loggedStatus === "reRequested") {
        setStatus(data.status);
        return;
      }
      setStatus(loggedStatus);
    } else {
      setStatus(data.status);
    }
  };

  const checkLoggedUser = (data) => {
    const isIt = data?.approvementChain?.find(
      (item) => item.staff.employee[0].id === currentEmployee.id
    );

    if (isIt) return isIt.status;
    else return false;
  };

  const isRejectedHandler = (data) => {
    const isRejected = data?.approvementChain?.find(
      (item) => item.status === "rejected"
    );
    if (isRejected) return isRejected.status;
    else return false;
  };

  const generateComments = (data) => {
    if (data?.docModel === "StructureRequest") {
      generateCommentsOfStructureReq(data);
      return;
    }

    const arr = [];
    const status = (status) => {
      switch (status) {
        case "approved":
          return "təsdiq edirəm";
        case "rejected":
          return "imtina edirəm";
        default:
          return "";
      }
    };
    const msg = "Şərh: ";

    data.approvementChain?.forEach((approver) => {
      const { firstname, lastname, position } = approver?.staff?.employee[0];
      if (approver.status === "pending") return;

      arr.push({
        label: `${msg}${firstname} ${lastname}${
          position?.name ? ` (${position.name})` : ""
        } - ${status(approver?.status)}`,
        value: approver.description,
      });
    });

    if (data.hrNotes?.length > 0) {
      data.hrNotes.map((item) => {
        arr.push({
          label: msg + "Yönləndirilmə səbəbi",
          value: item.description,
        });
      });
    }

    setComments([...arr]);
  };

  const generateCommentsOfStructureReq = (data) => {
    const arr = [];

    // data?.contract?.verifiers?.map((item) =>
    //   arr.push({
    //     label: `Təsdiqləyici -${" "}
    //     ${item?.employee?.firstname + " " + item?.employee?.lastname}`,
    //     value: item?.description,
    //   })
    // );

    data?.approvementChain?.map(
      (item) =>
        item.status !== "pending" &&
        arr.push({
          label: `Təsdiqləyici -${" "}
        ${
          item?.staff.employee?.firstname + " " + item?.staff.employee?.lastname
        }`,
          value: item?.description,
        })
    );

    const messageOwner =
      data.originalRequest?.creatorId?.firstname +
      " " +
      data.originalRequest?.creatorId?.lastname;

    data?.originalRequest?.message?.map((item) =>
      arr.push({
        label: `${messageOwner}: Struktur - ${" "}
        ${item?.key}`,
        value: item.value,
      })
    );

    setComments([...arr]);
  };

  const generateBgColor = () => {
    switch (status) {
      case "pending":
        return COLORS_STATUS.BLUE;
      case "approved":
        return COLORS_STATUS.GREEN;
      case "rejected":
        return COLORS_STATUS.RED;
      case REQUESTS_STATUS_TYPE.ReRequestedToCreator:
        return COLORS_STATUS.RED;
      case REQUESTS_STATUS_TYPE.CancelledByHR:
        return COLORS_STATUS.RED;
      case REQUESTS_STATUS_TYPE.PendingChainApprove:
        return COLORS_STATUS.BLUE;
      case REQUESTS_STATUS_TYPE.PendingOrder:
        return COLORS_STATUS.BLUE;
      case REQUESTS_STATUS_TYPE.PendingOrderApprove: // ????????????????????
        return COLORS_STATUS.GREEN;
      case REQUESTS_STATUS_TYPE.ReRequestedToManager:
        return COLORS_STATUS.ORANGE;
      case REQUESTS_STATUS_TYPE.RejectedByChainUser:
        return COLORS_STATUS.RED;
      case REQUESTS_STATUS_TYPE.UpdatedByCreator:
        return COLORS_STATUS.RED;
      default:
        return COLORS_STATUS.GREY;
    }
  };
  const generateHeader = () => {
    // const docType = findDocType(contractData?.contractType?.type);
    switch (status) {
      case "pending":
        return "Təsdiq gözləyən sorğu";
      case "approved":
        return "Təsdiqlənmiş sorğu";
      case "rejected":
        return "İmtina edilmiş sorğu";
      case REQUESTS_STATUS_TYPE.ReRequestedToCreator:
        return "Əməkdaşa yönləndirilmiş sorğu";
      case REQUESTS_STATUS_TYPE.CancelledByHR:
        return "HR tərəfindən imtina edilmiş";
      case REQUESTS_STATUS_TYPE.PendingChainApprove:
        return "Təsdiq gözləyən sorğu";
      case REQUESTS_STATUS_TYPE.PendingOrder:
        return "Sorğu ilə bağlı əmr gözlənilir";
      case REQUESTS_STATUS_TYPE.PendingOrderApprove:
        return "İmza gözləyən əmr"; // ??????????
      case REQUESTS_STATUS_TYPE.ReRequestedToManager:
        return "Yenidən yönləndirilmiş sorğu";
      case REQUESTS_STATUS_TYPE.RejectedByChainUser:
        return "İmtina edilmiş sorğu";
      case REQUESTS_STATUS_TYPE.UpdatedByCreator:
        return "Əməkdaş tərəfindən yenilənmiş sorğu";
      default:
        return "Sorğu";
    }
  };
  const generateFooter = (data) => {};

  // fields handlers
  const handlerFieldVacationRequest = (data) => {
    const arr = [
      {
        label: "Sorğunu yaradan",
        value:
          data?.creator.lastname +
          " " +
          data?.creator.firstname +
          " " +
          data?.creator.fathername,
      },
      {
        label: "Struktur (Departament)",
        value: data?.originalRequest.employee.structure,
      },
      // position: {
      //   label: "Vəzifə",
      //   value: originalRequest.position
      // },
      {
        label: "Sorğunun tipi",
        value:
          data?.docModel === "VacationRequest" ? "Məzuniyyət sorğusu" : "Sorğu",
      },
      {
        label: "Məzuniyyətin növü",
        value: data?.originalRequest.vacationType,
      },
      {
        label: "Məzuniyyətin başlama vaxtı",
        value: moment(data?.originalRequest.startDate).format("DD/MM/YYYY"),
      },
      {
        label: "Məzuniyyətin bitmə vaxtı",
        value: moment(data?.originalRequest.endDate).format("DD/MM/YYYY"),
      },
      {
        label: "Məzuniyyət günlərinin sayı",
        value: data?.originalRequest.totalVacationDays,
      },
      {
        label: "Istifadə olunan məzuniyyət günlərinin mənbəyi",
        value: data?.originalRequest.sourceOfVacation,
      },
      {
        label: "Əvəzləyici şəxs",
        value:
          data?.originalRequest.replacedBy.firstname +
          " " +
          data?.originalRequest.replacedBy.lastname,
      },
    ];

    data?.approvementChain.map((item, index) => {
      arr.splice(
        2 + index,
        0,
        {
          label: `Təsdiq edən ${index + 1}`,
          value:
            item.employee.lastname +
            " " +
            item.employee.firstname +
            " " +
            item.employee.fathername +
            " " +
            (item?.employee?.position?.name
              ? `(${item?.employee?.position?.name})`
              : ""),
        }
        // {
        //   label: `Təsdiq edən vəzifə ${index + 1}`,
        //   value: item.employee.position.name,
        // }
      );
    });

    setFields([...arr]);
  };

  const handlerFieldEmploymentRequest = (data) => {
    const arr = [
      {
        label: "Sorğunu yaradan",
        value:
          data?.creator.lastname +
          " " +
          data?.creator.firstname +
          " " +
          data?.creator.fathername,
      },
      {
        label: "Struktur (Departament)",
        value: data?.originalRequest.employee.structure,
      },
      // position: {
      //   label: "Vəzifə",
      //   value: originalRequest.position
      // },
      {
        label: "Sorğunun tipi",
        value: data?.docModel === "EmploymentRequest" ? "İş sorğusu" : "Sorğu",
      },
      {
        label: "Müqavilənin bağlandığı tarix",
        value: moment(data?.originalRequest.agreementDate).format("DD/MM/YYYY"),
      },
      {
        label: "Subyekt",
        value:
          data?.originalRequest.employee.lastname +
          " " +
          data?.originalRequest.employee.firstname +
          " " +
          data?.originalRequest.employee.fathername,
      },
      { label: "Ştat", value: data?.originalRequest.staff.staffUnit },
      { label: "Maaş", value: data?.originalRequest.staff.positionSalary },
    ];

    data?.approvementChain.map((item, index) => {
      arr.splice(
        2 + index,
        0,
        {
          label: `Təsdiq edən ${index + 1}`,
          value:
            item.employee.lastname +
            " " +
            item.employee.firstname +
            " " +
            item.employee.fathername +
            " " +
            (item?.employee?.position?.name
              ? `(${item?.employee?.position?.name})`
              : ""),
        }
        // {
        //   label: `Təsdiq edən vəzifə ${index + 1}`,
        //   value: item.employee.position.name,
        // }
      );
    });

    setFields([...arr]);
  };

  const handlerFieldStructure = (data) => {
    const arr = [
      {
        label: "Sənəd nömrəsi",
        value: data?.requestNO,
      },
      {
        label: "Sorğunu yaradan",
        value:
          data?.creator.lastname +
          " " +
          data?.creator.firstname +
          " " +
          data?.creator.fathername,
      },
      {
        label: "Struktur (Departament)",
        value: data?.originalRequest?.structureId?.organization?.name,
      },
      {
        label: "Sorğunun tipi",
        value:
          data?.docModel === "StructureRequest"
            ? "Struktur yeniləmə sorğusu"
            : "Sorğu",
      },
      {
        label: `Təsdiq edən`,
        value:
          data?.originalRequest?.structureId?.recruiter?.lastname +
          " " +
          data?.originalRequest?.structureId?.recruiter?.firstname +
          " " +
          data?.originalRequest?.structureId?.recruiter?.fathername +
          " " +
          (data?.originalRequest?.structureId?.recruiter?.position?.name
            ? `(${data?.originalRequest?.structureId?.recruiter?.position?.name})`
            : ""),
      },
    ];
    setFields([...arr]);
  };

  const handleOnSubmit = async (status, value) => {
    await handlerUpdateReq(contractData._id, status, value);
  };

  const hideSigningHandler = () => {
    if (
      status === "pending" ||
      status === REQUESTS_STATUS_TYPE.ReRequestedToManager
    )
      return false;
    return true;
  };

  const handlerNavButton = (value) => {
    window.open(`/employees/${value}/view`, "_blank", "noreferrer");
  };

  const editBtn = (
    <Grid
      width="100%"
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        height: "100%",
        alignItems: "center",
      }}
    >
      <Button sx={{ width: "50%" }}>
        <Typography>Düzəliş et</Typography>
      </Button>
    </Grid>
  );
  return (
    <DocumentDialog
      isGroup={true}
      loading={isLoading}
      open={open}
      onClose={onClose}
      commentsData={comments}
      options={{ bgColor: generateBgColor(), title: generateHeader() }}
      contractNo={contractData?.requestNO}
      fieldsData={fields}
      signingProps={{
        acceptBtnTitle: "Təsdiqlə",
        rejectBtnTitle: "İmtina et",
        hide: hideSigningHandler(),
        withoutVerifie: true,
      }}
      components={{
        footer: status === REQUESTS_STATUS_TYPE.ReRequestedToCreator && editBtn,
      }}
      commentsProps={{
        submitHandler: handleOnSubmit,
      }}
      navbarData={navList}
      navbarProps={{
        currTabValue: handlerNavButton,
        isLikeButton: true,
      }}
      downloadBtnProps={{
        generateHref: (value) =>
          `${BASE_URL}/employee/contract/${contractData?.contract?._id}/download`,
        value: contractData._id,
      }}
      typeDoc={contractData?.contract?.contractType?.name}
      versionData={versionsList}
      versionsProps={{
        versionHandler: async (_id) => {
          setFields([]);
          setComments();
          setStatus();
          setCurrVersion(_id);
          await getData(_id);
        },
        currVersionState: currVersion,
      }}
    />
  );
}
